import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryService } from './history.service';
import { SessionService } from './session.service';
import { FileUploadService } from './file-upload.service';
import { LicenseService } from './license.service';

const SERVICES = [
  HistoryService,
  SessionService,
  FileUploadService,
  LicenseService,
];

@NgModule({
  imports: [CommonModule],
  providers: [...SERVICES],
})
export class ServiceDataModule {
  static forRoot(): ModuleWithProviders<ServiceDataModule> {
    return {
      ngModule: ServiceDataModule,
      providers: [...SERVICES],
    };
  }
}
