import { Injectable } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { BoundsLiteral } from 'leaflet';
import { CookieService } from 'ngx-cookie-service';


@Injectable()
export class CookieTempService {

  public language: string;
  public theme: string;
  public cookieAccept: boolean;
  public showCookiePromt: boolean;
  public expire: Date;
  public themes = [
          {
            value: 'default',
            name: 'Light',
          },
          {
            value: 'dark',
            name: 'Dark',
          },
          {
            value: 'cosmic',
            name: 'Cosmic',
          },
          {
            value: 'corporate',
            name: 'Corporate',
          },
        ];

  constructor(
    public translateService: TranslateService,
    private cookieService: CookieService,
    private themeService: NbThemeService,
    ) {
      this.language = translateService.getBrowserLang();
      this.translateService.setDefaultLang(this.language);
      this.translateService.addLangs(['pl', 'en']);
      this.theme = 'default';
      this.cookieAccept = false;
      this.showCookiePromt = true;
      this.expire = new Date(new Date().getTime() + (365 * 24 * 60 * 60 * 1000));
      this.readCookie();
    }

    readCookie() {
       if (this.cookieService.get('aptbait-accept-cookie') === 'true') {
         this.cookieAccept = true;
         this.showCookiePromt = false;
      }
      if (this.cookieAccept) {
        // console.log('cookie');
        this.language = this.cookieService.get('aptbait-language');
        if (this.translateService.getLangs().find( e => e === this.language) !== undefined) {
          this.translateService.use(this.language);
        } else this.cookieService.set('aptbait-language', this.translateService.currentLang, this.expire);
        this.theme = this.cookieService.get('aptbait-theme');
        if (this.themes.find( e => e.value === this.theme) !== undefined) {
          this.themeService.changeTheme(this.theme);
        } else this.cookieService.set('aptbait-theme', this.themeService.currentTheme, this.expire);
      } else {
        this.language = this.translateService.getBrowserLang();
        this.translateService.use(this.language);
        this.themeService.changeTheme('default');
      }
    }

    public saveLanguage() {
      this.language = this.translateService.currentLang;
      this.cookieService.set('aptbait-language', this.language, this.expire);
    }

    public saveTheme() {
      this.theme = this.themeService.currentTheme;
      this.cookieService.set('aptbait-theme', this.theme, this.expire);
    }

    public acceptCookie() {
      this.showCookiePromt = false;
      this.cookieService.set('aptbait-accept-cookie', 'true', this.expire);
      this.cookieAccept = true;
      this.language = this.translateService.currentLang;
      this.cookieService.set('aptbait-language', this.language, this.expire);
      this.theme = this.themeService.currentTheme;
      this.cookieService.set('aptbait-theme', this.theme, this.expire);
    }
}
