import { Injectable } from '@angular/core';
import { CampaignsData, Statistics, CampaignProgressResponse, CampaignResponse, RecipientWithObjectives } from '../data/campaigns';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ListFetchOptions, RestFrameworkListResponse, RestObject } from '../data/rest-framework';

@Injectable()
export class CampaignsService extends CampaignsData {
  unimportant_request = 0;

  constructor(public http: HttpClient) {
    super('campaign', http);
  }

  public campaignClone(newCampaign): Observable<CampaignResponse | any> {
    return this.http.post<CampaignResponse>(this.url + this.endpoint + '/' + newCampaign.uid + '/clone/',
    {'name': newCampaign.name});
  }

  public campaignSendEduEmails(uid): Observable<CampaignResponse | any> {
    return this.http.get<any>(this.url + this.endpoint + '/' + uid + '/educate/');
  }

  public loadCampaignStatistics(uid): Observable<Statistics|any> {
    return this.http.get(this.url + this.endpoint + '/' + uid + '/statistics/');
  }

  public loadCampaignScenario(uid): Observable<any> {
    return this.http.get(this.url + '/campaign-scenario/?campaign_uid=' + uid );
  }

  public loadCampaignProgress(): Observable<CampaignProgressResponse|any> {
    return this.http.get(this.url + this.endpoint + '/progress/');
  }

  public campaignResendEmails(uid): Observable<CampaignResponse|any> {
    return this.http.get(this.url + this.endpoint + '/' + uid + '/retry/');
  }

  public campaignResendEduEmails(uid): Observable<CampaignResponse|any> {
    return this.http.get(this.url + this.endpoint + '/' + uid + '/eduretry/');
  }

  public loadRecipientsWithObjectives(uid): Observable<RecipientWithObjectives | any> {
    return this.http.get(`${this.url}recipient/WithObjectives/?campaign_scenario_uid=${uid}`);
  }

  public loadAllRecipients(uid): Observable<RecipientWithObjectives | any> {
    return this.http.get(`${this.url}recipient/?campaign_scenario_uid=${uid}`);
  }
}
