import { Injectable, Type } from '@angular/core';
import { PieGridComponent } from '@swimlane/ngx-charts';
import { O_DIRECT } from 'constants';
import { of as observableOf, Observable, of } from 'rxjs';
import { PersonGroup, PersonGroupData, PersonGroupResult } from '../data/person-group';

@Injectable()
export class PersonGroupsMockService extends PersonGroupData {

    private geneticUid = 'a5e2bd34-aeaa-4115-ade0-';
    private number = 194946111174;

    private generateUid(): string {
        const uid = this.geneticUid + String(this.number).toString();
        this.number++;
        return uid;
    }

    public getGroupPersons(uid: string): string[] {
        const dt: Array<any> = this.data[0].results as Array<any>;
        const d: Array<string> = [];
        for (let i = 0; i < dt.length; i++) {
            if (dt[i].group_uid === uid) d.push(String(dt[i].person_uid).toString());
        }
        return d;
    }

    public groupDelete(guid: string, uids: string[]): Observable<any> {
        const res: PersonGroupResult[] = this.data[0].results;
        for (let i = 0; i < uids.length; i++) {
            const pIndex = res.findIndex(e => e.person_uid === uids[i] && e.group_uid === guid);
            if (pIndex !== -1) {
                res.splice(pIndex, 1);
            }
        }
        return of(this.data);
    }

    public createMany(uid: string, uids: string[]): Observable<any> {
        uids.forEach(u => {
            const pg: any = {
                'uid': this.generateUid(),
                'group_uid': uid,
                'person_uid': u,
            };
            this.data[0].results.push(pg);
        });
        return of(this.data);
    }


    public educateMany(_: string[]): Observable<any> {
        return of({
            status: 'success',
        });
    }

    constructor() {
        super('persongroup', [
            {
                'next': null,
                'previous': null,
                'count': 'results'.length,
                'max_page_size': 200,
                'results': [
                    {
                        'uid': 'd1e1bd34-aeda-4125-ade1-194946f3ec74',
                        'group_uid': '4d0500fe-eb07-4acb-8539-010d8aa88d20',
                        'person_uid': '05c4f446-a31c-42be-ba62-4e9a7f432402',
                    },
                    {
                        'uid': '1234ad3a-e4e5-4d15-b438-3c3e421fc5e2',
                        'group_uid': '4d0500fe-eb07-4acb-8539-010d8aa88d20',
                        'person_uid': '14ca3d03-f30a-4b78-ba3b-d27e883be5a1',
                    },
                    {
                        'uid': '25',
                        'group_uid': '4d0500fe-eb07-4acb-8539-010d8aa88d20',
                        'person_uid': '1d4e983b-829f-4d73-b509-0a552795ad86',
                    },
                ],
            },
        ],
        );
    }
}
