import { Injectable } from '@angular/core';
import {
  CheckEdumailRequest,
  CheckEdumailResponse,
  CheckEdumailData,
} from '../data/check-edumail';
import { Observable, of } from 'rxjs';

@Injectable()
export class CheckEdumailMockService extends CheckEdumailData {
  constructor() {
    super('CHECK-EDUMAIL', []);
  }

  public test(
    req: CheckEdumailRequest,
  ): Observable<CheckEdumailResponse | any> {
    return of({
      status: 'success',
    });
  }
}
