import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  EmailTestRequest,
  EmailTestResponse,
  EmailTestData,
} from '../data/email-test';
import { Observable, of } from 'rxjs';

@Injectable()
export class EmailTestService extends EmailTestData {
  constructor(public http: HttpClient) {
    super('check-mail-server', http);
  }

  public test(req: EmailTestRequest): Observable<EmailTestResponse | any> {
    return this.http.post<EmailTestResponse>(
      this.url + this.endpoint + '/',
      req,
    );
  }
}
