import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { RestObject } from './rest-framework';

export interface History extends RestObject {
  message: string;
  details: string;
  level: string;
  timestamp: string;
}

export abstract class HistoryData extends RestFrameworkService<History> {}
