import {
  ListFetchOptions,
  ListFetchTypeOptions,
  RestFrameworkListResponse,
  RestObject,
} from '../data/rest-framework';
import {
  HttpClient,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { of as observableOf, Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { GenericApiService } from './genericapi.service';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { Injectable } from '@angular/core';
import { LoggerService } from '../utils/logger.service';
import { environment } from '../../../environments/environment';
import { PersonsUploadResponse } from '../data/persons';
import { id } from '@swimlane/ngx-charts';

const NO_TOAST_URLS = ['/api/token/refresh/', '/api/token/'];

@Injectable()
export class RestFrameworkHttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastrService: NbToastrService,
    private log: LoggerService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(environment.request_retry_number),
      catchError((error: HttpErrorResponse) => {
        this.log.debug(
          '[RestFrameworkHttpErrorInterceptor][intercept] Request retry failed: ',
          request,
        );
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        if (NO_TOAST_URLS.indexOf(request.url) === -1) {
          const config = {
            status: 'danger',
            destroyByClick: true,
            duration: 5000,
            hasIcon: true,
            position: NbGlobalPhysicalPosition.TOP_RIGHT,
            preventDuplicates: false,
          };
          const titleContent = 'API Error';
          this.toastrService.show(errorMessage, titleContent, config);
        }
        return throwError(errorMessage);
      }),
    );
  }
}

export abstract class RestFrameworkService<Type> extends GenericApiService {
  protected endpoint: string;
  protected data: HttpClient | Array<Type>;

  protected constructor(endpoint, data: HttpClient | Array<Type>) {
    super();
    this.data = data;
    this.endpoint = endpoint;
  }

  public isMock(): boolean {
    return !(this.data instanceof HttpClient);
  }

  private mockCreate(obj: Type): Observable<Type | any> {
    const d: Array<Type> = this.data as Array<Type>;
    obj['uid'] = d.length;
    d.push(obj);
    this.data = d;
    return observableOf(obj);
  }

  public mockRead(uid: string): Observable<Type | any> {
    const d: Array<Type> = this.data as Array<Type>;
    const res = d.filter((v) => 'uid' in v && v['uid'] === uid);
    if (res.length !== 0) return observableOf(res[0]);
    else return observableOf({});
  }

  public mockUpdate(obj: Type): Observable<Type | any> {
    return new Observable<Type | any>((subscriber) => {
      this.mockRead(obj['uid']).subscribe((db_obj) => {
        const d: Array<Type> = this.data as Array<Type>;
        const idx = d.indexOf(db_obj);
        if (idx !== -1) {
          d[idx] = obj;
          this.data = d;
          subscriber.next(obj);
        } else subscriber.next({});
        subscriber.complete();
      });
    });
  }

  public mockDelete(uid: string): Observable<any> {
    return new Observable<Type | any>((subscriber) => {
      this.mockRead(uid).subscribe((db_obj) => {
        const d: Array<Type> = this.data as Array<Type>;
        const idx = d.indexOf(db_obj);
        if (idx !== -1) {
          d.splice(idx, 1);
          this.data = d;
        }
        subscriber.next({});
        subscriber.complete();
      });
    });
  }

  public mockDeleteMany(uids: string[]): Observable<any> {
    const d: Array<Type> = this.data as Array<Type>;
    return new Observable<Type | any>((subscriber) => {
      uids.forEach((uid) => {
        this.mockRead(uid).subscribe((db_obj) => {
          const idx = d.indexOf(db_obj);
          if (idx !== -1) {
            d.splice(idx, 1);
            this.data = d;
          }
          subscriber.next({});
          subscriber.complete();
        });
      });
    });

  }


  public mockReadList(options: ListFetchOptions): Observable<RestFrameworkListResponse<Type> | any> {
    let d: Array<Type> = this.data as Array<Type>;
    if (options.search) {
      d = d.filter((v) => {
        let search = '';
        Object.keys(v).forEach((key) => {
          search += ' ' + v[key];
        });
        return search.indexOf(options.search) !== -1;
      });
    }
    const page: number = options.page ? options.page : 1;
    const page_size: number = options.page_size ? options.page_size : 20;
    const out = d.slice(
      (page - 1) * page_size,
      (page - 1) * page_size + page_size,
    );
    return observableOf({
      next: null,
      previous: null,
      count: d.length,
      max_page_size: 100,
      results: out,
    });
  }

  public mockReadListType(options: ListFetchTypeOptions): Observable<RestFrameworkListResponse<Type> | any> {
    let d: Array<Type> = this.data as Array<Type>;
    if (options.search) {
      d = d.filter((v) => {
        let search = '';
        Object.keys(v).forEach((key) => {
          search += ' ' + v[key];
        });
        return search.indexOf(options.search) !== -1;
      });
    }

    const page: number = options.page ? options.page : 1;
    const page_size: number = options.page_size ? options.page_size : 20;
    const out = d.slice(
      (page - 1) * page_size,
      (page - 1) * page_size + page_size,
    );
    return observableOf({
      next: null,
      previous: null,
      count: d.length,
      max_page_size: 100,
      results: out,
    });
  }

  public mockReadAll(): Observable<RestFrameworkListResponse<Type> | any> {
    const arr: Array<Type> = this.data as Array<Type>;
    return observableOf(arr);
  }

  public create(obj: Type): Observable<Type | any> {
    if (this.isMock()) {
      return this.mockCreate(obj);
    }
    return (this.data as HttpClient).post<Type>(
      this.url + this.endpoint + '/',
      obj,
    );
  }

  public read(uid: string): Observable<Type | any> {
    if (this.isMock()) {
      return this.mockRead(uid);
    }
    return (this.data as HttpClient).get<Type>(
      this.url + this.endpoint + '/' + uid + '/',
    );
  }

  public update(obj: Type | any): Observable<Type | any> {
    if (this.isMock()) {
      return this.mockUpdate(obj);
    }
    if (Object.keys(obj)) {
      const uid = obj['uid'];
      return (this.data as HttpClient).patch<Type>(
        this.url + this.endpoint + '/' + uid + '/',
        obj,
      );
    } else {
      throwError('Object without uid can not be patched!');
    }
  }

  public genericUploadContentMultipart<T_RESPONSE>(
    req: File,
    full_endpoint_address: string,
  ): Observable<T_RESPONSE | any> {
    const fd = new FormData();
    const reader = new FileReader();
    return new Observable<T_RESPONSE>((subs) => {
      reader.addEventListener('loadend', (e) => {
        const body = new Blob([reader.result]);
        fd.append('file', body);
        (this.data as HttpClient)
          .post<T_RESPONSE>(full_endpoint_address, fd)
          .subscribe((pr: T_RESPONSE) => {
            subs.next(pr);
            subs.complete();
          });
      });
      reader.readAsArrayBuffer(req);
    });
  }


  public delete(uid: string): Observable<any> {
    if (this.isMock()) {
      return this.mockDelete(uid);
    }
    return (this.data as HttpClient).delete<any>(
      this.url + this.endpoint + '/' + uid + '/',
    );
  }


  public deleteMany(uids: any): Observable<any> {
    if (this.isMock()) {
      return this.mockDeleteMany(uids);
    }
    return (this.data as HttpClient).request<any>('delete',
      this.url + this.endpoint + '/delete_many/',
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: {
          'uuids': uids,
        },
      },
    );
  }

  public readList(
    options: ListFetchOptions,
  ): Observable<RestFrameworkListResponse<Type> | any> {
    if (this.isMock()) {
      return this.mockReadList(options);
    }
    let url = `${this.url}${this.endpoint}/?`;
    if (options.page) url += `&page=${options.page}`;
    if (options.page_size) url += `&page_size=${options.page_size}`;
    if (options.search) url += `&search=${options.search}`;
    if (options.ordering) url += `&ordering=${options.ordering}`;
    url = url.replace('?&', '?');
    return (this.data as HttpClient).get<RestFrameworkListResponse<Type>>(url);
  }

  public readListType(
    options: ListFetchTypeOptions,
  ): Observable<RestFrameworkListResponse<Type> | any> {
    if (this.isMock()) {
      return this.mockReadListType(options);
    }
    let url = `${this.url}${this.endpoint}/?a`;
    if (options.page) url += `&page=${options.page}`;
    if (options.page_size) url += `&page_size=${options.page_size}`;
    if (options.search) url += `&search=${options.search}`;
    if (options.ordering) url += `&ordering=${options.ordering}`;
    if (options.type) url += `&type=${options.type}`;
    return (this.data as HttpClient).get<RestFrameworkListResponse<Type>>(url);
  }

  public readByTypeAndUid(
    options: ListFetchTypeOptions, uid: string,
  ): Observable<RestFrameworkListResponse<Type> | any> {
    if (this.isMock()) {
      return this.mockReadListType(options);
    }
    let url = `${this.url}${this.endpoint}/?`;
    if (options.page) url += `page=${options.page}`;
    if (options.page_size) url += `&page_size=${options.page_size}`;
    if (options.search) url += `&search=${options.search}`;
    if (options.ordering) url += `&ordering=${options.ordering}`;
    if (options.type) url += `&${options.type}=${uid}`;
    return (this.data as HttpClient).get<RestFrameworkListResponse<Type>>(url);
  }

  public readAll(): Observable<Type[] | any> {
    if (this.isMock()) {
      return this.mockReadAll();
    }
    const out: Type[] = [];
    const obs = new Observable<Type[]>((observer) => {
      const parse_next = (result: RestFrameworkListResponse<Type>) => {
        out.push(...result.results);
        if (result.next !== null) {
          (this.data as HttpClient)
            .get<RestFrameworkListResponse<Type>>(result.next)
            .subscribe(parse_next);
        } else {
          observer.next(out);
          observer.complete();
        }
      };
      (this.data as HttpClient)
        .get<RestFrameworkListResponse<Type>>(this.url + this.endpoint + '/')
        .subscribe(parse_next);
    });
    return obs;
  }
}
