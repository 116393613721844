import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import { LoggerService } from '../utils/logger.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private authService: NbAuthService,
    private router: Router,
    private log: LoggerService,
  ) {
  }

  canActivate() {
    return this.authService.isAuthenticatedOrRefresh()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.log.info('[AuthGuard][canActivate] Detected unauthorized. Redirecting to login.');
            this.router.navigate(['auth/login']);
          }
        }),
      );
  }

  canActivateChild() {
    return this.authService.isAuthenticatedOrRefresh()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.log.info('[AuthGuard][CanActivateChild] Detected unauthorized. Redirecting to login.');
            this.router.navigate(['auth/login']);
          }
        }),
      );
  }
}
