import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { ListFetchOptions, RestObject } from './rest-framework';
import { Persons } from './persons';
import { ExtraComponentsComponent } from '../../pages/extra-components/extra-components.component';

export interface PersonStatistics extends Persons {
  campaigns: number;
  csae: boolean;
  effective3: EffectiveSceanrio[];
  events: number;
  exams: Csae;
  last3: UsedScenario[];
  mails: number;
  score: number;
  score_history: [number];
  severities: Severities;
}

export interface Severities {
  critical: number;
  high: number;
  medium: number;
  low: number;
}

export interface Csae {
  csae_progress: number;
  csae_is_finished: boolean;
  csae_final_exam_score: number;
  attempts: [];
}

export interface EffectiveSceanrio {
  name: string;
  counts: number;
}

export interface UsedScenario {
  name: string;
  counts: number;
}

export abstract class StatisticsUsersData extends RestFrameworkService<PersonStatistics | Persons> {
  public abstract readPersonsStatistics(options, type: string): Observable<Persons | any>;
}
