import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PersonGroupData } from '../data/person-group';
import { Observable } from 'rxjs';

@Injectable()
export class PersonGroupService extends PersonGroupData {

  public getGroupPersons(uid: string): string[] {
    throw new Error('Method not implemented.');
  }

  constructor(public http: HttpClient) {
    super('persongroup', http);
  }

  public groupDelete(uid: string, uids: string[]): Observable<any> {
    return (this.data as HttpClient).request<any>('delete',
      this.url + this.endpoint + '/delete_group/',
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: {
          'uid': uid,
          'uuids': uids,
        },
      },
    );
  }

  public createMany(uid: string, uids: string[]): Observable<any> {
    return (this.data as HttpClient).request<any>('post',
      this.url + this.endpoint + '/create_many/',
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: {
          'guid': uid,
          'uuids': uids,
        },
      },
    );
  }

  public educateMany(uids: string[]): Observable<any> {
    return (this.data as HttpClient).request<any>('post',
      this.url + this.endpoint + '/educate_many/',
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: {
          'uuids': uids,
        },
      },
    );
  }

}
