import { Injectable } from '@angular/core';
import { IpFilteringSettingsData } from '../data/ip-filtering-settings';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class IpFilteringSettingsService extends IpFilteringSettingsData {
  constructor(public http: HttpClient) {
    super('ip-blacklist', http);
  }
}
