<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">APT-<span>Bait</span></a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action">
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user"
               color="#cccccc"
               nbContextMenuTag="user-menu"
      >
      </nb-user>
    </nb-action>
 
  </nb-actions>
  <nb-select class="ml-2" [selected]="cookieService.language" (selectedChange)="changeLanguage($event)" status="primary">
    <nb-option *ngFor="let lang of translate.getLangs()" [value]="lang"> {{ lang }}</nb-option>
  </nb-select>
  <nb-select class="ml-2" [selected]="cookieService.theme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of cookieService.themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
</div>
