import {
  Injectable,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { environment } from '../../environments/environment';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  PlayerService,
  SeoService,
  StateService,
} from './utils';

import { UserData } from './data/users';
import { ElectricityData } from './data/electricity';
import { SmartTableData } from './data/smart-table';
import { UserActivityData } from './data/user-activity';
import { OrdersChartData } from './data/orders-chart';
import { ProfitChartData } from './data/profit-chart';
import { TrafficListData } from './data/traffic-list';
import { EarningData } from './data/earning';
import { OrdersProfitChartData } from './data/orders-profit-chart';
import { TrafficBarData } from './data/traffic-bar';
import { ProfitBarAnimationChartData } from './data/profit-bar-animation-chart';
import { TemperatureHumidityData } from './data/temperature-humidity';
import { SolarData } from './data/solar';
import { TrafficChartData } from './data/traffic-chart';
import { StatsBarData } from './data/stats-bar';
import { CountryOrderData } from './data/country-order';
import { StatsProgressBarData } from './data/stats-progress-bar';
import { VisitorsAnalyticsData } from './data/visitors-analytics';
import { SecurityCamerasData } from './data/security-cameras';
import { UserService } from './mock/users.service';
import { ElectricityService } from './mock/electricity.service';
import { SmartTableService } from './mock/smart-table.service';
import { UserActivityService } from './mock/user-activity.service';
import { OrdersChartService } from './mock/orders-chart.service';
import { ProfitChartService } from './mock/profit-chart.service';
import { TrafficListService } from './mock/traffic-list.service';
import { EarningService } from './mock/earning.service';
import { OrdersProfitChartService } from './mock/orders-profit-chart.service';
import { TrafficBarService } from './mock/traffic-bar.service';
import { ProfitBarAnimationChartService } from './mock/profit-bar-animation-chart.service';
import { TemperatureHumidityService } from './mock/temperature-humidity.service';
import { SolarService } from './mock/solar.service';
import { TrafficChartService } from './mock/traffic-chart.service';
import { StatsBarService } from './mock/stats-bar.service';
import { CountryOrderService } from './mock/country-order.service';
import { StatsProgressBarService } from './mock/stats-progress-bar.service';
import { VisitorsAnalyticsService } from './mock/visitors-analytics.service';
import { SecurityCamerasService } from './mock/security-cameras.service';
import { MockDataModule } from './mock/mock-data.module';
import { ServiceDataModule } from './services/service-data.module';
import {
  consoleEngine,
  LOGGER_ENGINE,
  LoggerService,
} from './utils/logger.service';
import { RoleProvider } from './security/RoleProvider';
// EMAIL-ACCOUNTS
import { EmailAccountSettingsData } from './data/email-account-settings';
import { EmailAccountSettingsService } from './services/email-account.service';
import { EmailAccountSettingsMockService } from './mock/email-account-settings.mock.service';
// EMAIL TEST
import { EmailTestData } from './data/email-test';
import { EmailTestService } from './services/email-test.service';
import { EmailTestMockService } from './mock/email-test.mock.service';
// INTERVALS
import { SettingsIntervalsData } from './data/settings-intervals';
import { SettingsIntervalsService } from './services/settings-intervals.service';
import { SettingsIntervalsMockService } from './mock/settings-intervals.mock.service';
// EDUCATION  LOGO
import { EduLogoData } from './data/edulogo';
import { EduLogoService } from './services/edulogo.service';
import { CheckEdumailData } from './data/check-edumail';
import { CheckEdumailService } from './services/check-edumail.service';
import { CheckEdumailMockService } from './mock/check-edumail.mock.service';
// IP FILTERING
import { IpFilteringSettingsData } from './data/ip-filtering-settings';
import { IpFilteringSettingsService } from './services/ip-filtering-settings.service';
import { IpFilteringSettingsMockService } from './mock/ip-filtering-settings.mock.service';
// COUNTRY FILTERING
import { CountryFilteringSettingsData } from './data/country-filtering-settings';
import { CountryFilteringSettingsService } from './services/country-filtering-settings.service';
import { CountryFilteringSettingsMockService } from './mock/country-filtering-settings.mock.service';
// FILTER SETTING
import { FilterSettingData } from './data/filter-setting';
import { FilterSettingService } from './services/filter-setting.service';
import { FilterSettingMockService } from './mock/filter-setting.mock.service';
// SESSION
import { SessionData } from './data/session';
import { SessionService } from './services/session.service';
import { SessionMockService } from './mock/session.mock.service';
// PERSONS
import { PersonService } from './services/person.service';
import { PersonsData } from './data/persons';
import { PersonsMockService } from './mock/persons.mock.service';
import { FileUploadService } from './services/file-upload.service';
// LOG
import { HistoryData } from './data/history';
import { HistoryService } from './services/history.service';
import { HistoryMockService } from './mock/history.mock.service';
// EVENTS
import { EventsData } from './data/events';
import { EventsService } from './services/events.service';
import { EventsMockService } from './mock/events.mock.service';
// LICENSE
import { LicenseData } from './data/license';
import { LicenseService } from './services/license.service';
import { LicenseMockService } from './mock/license.mock.service';
// SUPPORT
import { SupportData } from './data/support';
import { SupportService } from './mock/support.service';
// CAMPAIGN
import { CampaignsData } from './data/campaigns';
import { CampaignsService } from './services/campaigns.service';
import { CampaignsMockService } from './mock/campaigns.mock.service';
import { from } from 'rxjs';
// Group
import { GroupData } from './data/group';
import { GroupService } from './services/groups.service';
import { GroupsMockService } from './mock/groups.mock.service';
// PersonGroup
import { PersonGroupData } from './data/person-group';
import { PersonGroupsMockService } from './mock/person-group.mock.service';
import { PersonGroupService } from './services/person-group.service';
// TAG
import { TagData } from './data/tag';
import { TagService } from './services/tag.service';
import { TagMockService } from './mock/tag.mock.service';
// SCENARIO
import { ScenarioData } from './data/scenario';
import { ScenarioService } from './services/scenario.service';
import { ScenarioMockService } from './mock/scenario.mock.service';
// VICTIM
import { VictimsData } from './data/victim';
import { VictimService } from './services/victim.service';
import { VictimMockService } from './mock/victim.mock.service';
// C2MODULES
import { C2ModulesData } from './data/c2modules';
import { C2ModulesService } from './services/c2modules.service';
import { C2ModulesMockService } from './mock/c2modules.mock.service';
// CMD
import { CmdData } from './data/cmd';
import { CmdService } from './services/cmd.service';
// Other
import { FileUploadMockService } from './mock/file-upload.mock.service';
import { CmdMockService } from './mock/cmd.mock.service';
// OBJECTIVES
import { ObjectivesData } from './data/objectives';
import { ObjectivesService } from './services/objectives.service';
import { ObjectivesMockService } from './mock/objectives.mock.service';
// PARAMETERS
import { ParametersData } from './data/parameters';
import { ParametersService } from './services/parameters.service';
import { ParametersMockService } from './mock/parameters.mock.service';
// DASHBOARD
import { DashboardData } from './data/dashboard';
import { DashboardService } from './services/dashboard.service';
// STATISTICS USER
import { StatisticsUsersData } from './data/statistics-users';
import { StatisticsUsersService } from './services/statistics-users.service';
import { StatisticsUsersMockService } from './mock/statistics-users.mock.service';
// STATISTICS USER
import { StatisticsGeneralData } from './data/statistics-general';
import { StatisticsGeneralService } from './services/statistics-general.service';
// import { StatisticsUsersMockService } from './mock/statistics-users.mock.service';

const DATA_SERVICES = [
  {
    provide: HistoryData,
    useClass: environment.use_mock ? HistoryMockService : HistoryService,
  },
  {
    provide: DashboardData,
    useClass: environment.use_mock ? DashboardService : DashboardService,
  },
  {
    provide: TagData,
    useClass: environment.use_mock ? TagMockService : TagService,
  },
  {
    provide: EventsData,
    useClass: environment.use_mock ? EventsMockService : EventsService,
  },
  {
    provide: SessionData,
    useClass: environment.use_mock ? SessionMockService : SessionService,
  },
  {
    provide: StatisticsUsersData,
    useClass: environment.use_mock ? StatisticsUsersMockService : StatisticsUsersService,
  },
  {
    provide: StatisticsGeneralData,
    useClass: environment.use_mock ? StatisticsGeneralService : StatisticsGeneralService,
  },
  {
    provide: EmailAccountSettingsData,
    useClass: environment.use_mock
      ? EmailAccountSettingsMockService
      : EmailAccountSettingsService,
  },
  {
    provide: SettingsIntervalsData,
    useClass: environment.use_mock
      ? SettingsIntervalsMockService
      : SettingsIntervalsService,
  },
  { provide: GroupData, useClass: environment.use_mock ? GroupsMockService : GroupService },
  { provide: PersonGroupData, useClass: environment.use_mock ? PersonGroupsMockService : PersonGroupService },
  {
    provide: LicenseData,
    useClass: environment.use_mock ? LicenseMockService : LicenseService,
  },
  {
    provide: CampaignsData,
    useClass: environment.use_mock ? CampaignsMockService : CampaignsService,
  },
  {
    provide: ScenarioData,
    useClass: environment.use_mock ? ScenarioMockService : ScenarioService,
  },
  {
    provide: ObjectivesData,
    useClass: environment.use_mock ? ObjectivesMockService : ObjectivesService,
  },
  {
    provide: ParametersData,
    useClass: environment.use_mock ? ParametersMockService : ParametersService,
  },
  {
    provide: EmailTestData,
    useClass: environment.use_mock ? EmailTestMockService : EmailTestService,
  },
  {
    provide: IpFilteringSettingsData,
    useClass: environment.use_mock
      ? IpFilteringSettingsMockService
      : IpFilteringSettingsService,
  },
  {
    provide: CountryFilteringSettingsData,
    useClass: environment.use_mock
      ? CountryFilteringSettingsMockService
      : CountryFilteringSettingsService,
  },
  {
    provide: FilterSettingData,
    useClass: environment.use_mock
      ? FilterSettingMockService
      : FilterSettingService,
  },
  {
    provide: EduLogoData,
    useClass: EduLogoService,
  },
  {
    provide: CheckEdumailData,
    useClass: environment.use_mock
      ? CheckEdumailMockService
      : CheckEdumailService,
  },
  {
    provide: VictimsData,
    useClass: environment.use_mock ? VictimMockService : VictimService,
  },
  {
    provide: C2ModulesData,
    useClass: environment.use_mock ? C2ModulesMockService : C2ModulesService,
  },
  {
    provide: CmdData,
    useClass: environment.use_mock ? CmdMockService : CmdService,
  },
  { provide: SupportData, useClass: SupportService },
  { provide: UserData, useClass: UserService },
  { provide: ElectricityData, useClass: ElectricityService },
  { provide: SmartTableData, useClass: SmartTableService },
  { provide: UserActivityData, useClass: UserActivityService },
  { provide: OrdersChartData, useClass: OrdersChartService },
  { provide: ProfitChartData, useClass: ProfitChartService },
  { provide: TrafficListData, useClass: TrafficListService },
  { provide: EarningData, useClass: EarningService },
  { provide: OrdersProfitChartData, useClass: OrdersProfitChartService },
  { provide: TrafficBarData, useClass: TrafficBarService },
  {
    provide: ProfitBarAnimationChartData,
    useClass: ProfitBarAnimationChartService,
  },
  { provide: TemperatureHumidityData, useClass: TemperatureHumidityService },
  { provide: SolarData, useClass: SolarService },
  { provide: TrafficChartData, useClass: TrafficChartService },
  { provide: StatsBarData, useClass: StatsBarService },
  { provide: CountryOrderData, useClass: CountryOrderService },
  { provide: StatsProgressBarData, useClass: StatsProgressBarService },
  { provide: VisitorsAnalyticsData, useClass: VisitorsAnalyticsService },
  { provide: SecurityCamerasData, useClass: SecurityCamerasService },
  { provide: PersonsData, useClass: environment.use_mock ? PersonsMockService : PersonService },
  { provide: FileUploadService, useClass: environment.use_mock ? FileUploadMockService : FileUploadService },
];

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...ServiceDataModule.forRoot().providers,
  ...DATA_SERVICES,
  NbSecurityModule.forRoot({
    accessControl: {
      GUEST: {},
      RO: {
        parent: 'GUEST',
        view: '*',
      },
      RW: {
        parent: 'RO',
        create: [
          'person',
          'group',
          'persongroup',
          'campaign',
          'settings',
          'commandandcontrol',
          'support',
        ],
        edit: [
          'person',
          'group',
          'persongroup',
          'campaign',
          'settings',
          'commandandcontrol',
          'support',
        ],
        remove: [
          'person',
          'group',
          'persongroup',
          'campaign',
          'settings',
          'commandandcontrol',
          'support',
        ],
      },
      SU: {
        parent: 'RW',
        edit: ['scenario'],
        remove: ['scenario'],
      },
    },
  }).providers,
  {
    provide: NbRoleProvider,
    useClass: RoleProvider,
  },
  AnalyticsService,
  LayoutService,
  PlayerService,
  SeoService,
  StateService,
  LoggerService,
];

@NgModule({
  imports: [CommonModule],
  exports: [NbAuthModule],
  declarations: [],
  providers: [
    {
      provide: LOGGER_ENGINE,
      useValue: consoleEngine,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS],
    };
  }
}
