import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Persons } from '../data/persons';
import { Observable, of } from 'rxjs';
import { ListFetchOptions, ListFetchTypeOptions, RestFrameworkListResponse } from '../data/rest-framework';
import { StatisticsUsersData } from '../data/statistics-users';

@Injectable()
export class StatisticsUsersMockService extends StatisticsUsersData {

  constructor() {
    super('statUser', [
      {
        uid: '05c4f446-a31c-42be-ba62-4e9a7f432402',
        firstname: 'barMock',
        lastname: 'Fabjanczuk',
        isMale: true,
        position: 'CEO',
        email: 'bartosz.fabjanczuk@mysza1.software19',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
    ]);
  }

  public readPersonsStatistics( options: ListFetchOptions, type: string): Observable<Persons | any> {
    return of({
      count: 1,
      max_page_size: 200,
      next: null,
      previous: null,
      results: this.data as Array<Persons>,
    });
  }
}
