import { RestFrameworkService } from '../services/rest-framework-service';
import { RestObject } from './rest-framework';

export interface EmailAccountSettings extends RestObject {
  uid?: string;
  host: string;
  description: string;
  sender_name: string;
  sender_email: string;
  port: number;
  encryption: string;
  login: string;
  password: string;
  ignore_certyficate: boolean;
}

export abstract class EmailAccountSettingsData extends RestFrameworkService<EmailAccountSettings> {}
