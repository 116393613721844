import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { LicenseData, LicenseResponse } from '../data/license';

@Injectable()
export class LicenseMockService extends LicenseData {
  constructor() {
    super('license', []);
  }

  public getLicense(): Observable<LicenseResponse | any> {
    return of({
        ValidFor: 'Anonimowa',
        IsActive: true,
        ValidUntil: '',
    });
  }
}
