import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CmdData, Cmd } from '../data/cmd';
import { ListFetchOptionsForVictim } from '../data/rest-framework';
import { Observable } from 'rxjs';

@Injectable()
export class CmdService extends CmdData {
  constructor(public http: HttpClient) {
    super('cmd', http);
  }

  public readCmdList(
    options: ListFetchOptionsForVictim,
  ): Observable<Cmd | any> {
    if (this.isMock()) {
      return this.mockReadList(options);
    }
    let url = `${this.url}${this.endpoint}/?`;
    if (options.filter) url += `victim_uid=${options.filter}`;
    if (options.page) url += `&page=${options.page}`;
    if (options.page_size) url += `&page_size=${options.page_size}`;
    if (options.search) url += `&search=${options.search}`;
    if (options.ordering) url += `ordering=${options.ordering}`;
    return (this.data as HttpClient).get<Cmd>(url);
  }
}
