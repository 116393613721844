import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { ListFetchOptions, RestObject } from './rest-framework';

export interface Objective extends RestObject {
  code: string;
  severity: string;
  description: string;
  scenario_uid: string;
  awareness_content: string;
}

export abstract class ObjectivesData extends RestFrameworkService<Objective> {
  public abstract readObjectivesList(_: ListFetchOptions, uid: RestObject): Observable<Objective | any>;
}
