import { Injectable } from '@angular/core';
import { Persons } from '../data/persons';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListFetchOptions, RestFrameworkListResponse, RestObject } from '../data/rest-framework';
import { StatisticsGeneralData } from '../data/statistics-general';

@Injectable()
export class StatisticsGeneralService extends StatisticsGeneralData {

  constructor(public http: HttpClient) {
    super('statsgeneral', http);
  }

}
