import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ScenarioData } from '../data/scenario';
import { of } from 'rxjs';
import { RestObject } from '../data/rest-framework';

@Injectable()
export class ScenarioMockService extends ScenarioData {
  constructor() {
    super('SCENARIO', [
      {
        uid: '4db5464f-007c-4087-bf6a-b45f6c273419',
        name: 'Oferta pracy - rekruteka.pl',
        description: 'Fałszywa oferta pracy w dokumencie Worda zawierającym złośliwe makro',
        scenariotype: 'E',
        url: 'https://rekruteka.pl/2',
        mail_subject: 'Interesująca propozycja pracy w branży finansowej na stanowisku %PERSON.POSITION%',
        mail_body_male: '<p>Dzień dobry,</p>\r\n<p>mam do Pana namiar od naszej wsp&oacute;lnej znajomej. Zainteresował mnie Pana profil i doświadczenie, w kontekście czego pozwoliłam sobie na przygotowanie oferty pracy dla jednego z naszych największych klient&oacute;w z branży finansowej.</p>\r\n<p>Od razu dopowiem, że nie bardzo jest już miejsce na negocjacje finansowe, ale mam nadzieję, że po obejrzeniu oferty nie będzie takiej potrzeby. :)</p>\r\n<p>Nasza propozycja znajduje się do wglądu pod adresem <a href=\'%SCENARIO.LINK%\'>%SCENARIO.LINK%</a>.</p>\r\n<p>W razie jakichkolwiek pytań jestem do dyspozycji.</p>\r\n<p>Iwona Bober<br />Dyrektor do spraw Kluczowych Partner&oacute;w<br />Rekruteka<br />http://rekruteka.pl/</p>',
        mail_body_female: '<p>Dzień dobry,</p>\r\n<p>mam do Pani namiar od naszej wsp&oacute;lnej znajomej. Zainteresował mnie Pani profil i doświadczenie, w kontekście czego pozwoliłam sobie na przygotowanie oferty pracy dla jednego z naszych największych klient&oacute;w z branży finansowej.</p>\r\n<p>Od razu dopowiem, że nie bardzo jest już miejsce na negocjacje finansowe, ale mam nadzieję, że po obejrzeniu oferty nie będzie takiej potrzeby. :)</p>\r\n<p>Nasza propozycja znajduje się do wglądu pod adresem <a href=\'%SCENARIO.LINK%\'>%SCENARIO.LINK%</a>.</p>\r\n<p>W razie jakichkolwiek pytań jestem do dyspozycji.</p>\r\n<p>Iwona Bober<br />Dyrektor do spraw Kluczowych Partner&oacute;w<br />Rekruteka<br />http://rekruteka.pl/</p>',
        awareness_content: '<p><strong>Witaj %PERSON.FIRSTNAME%.</strong></p>\r\n<p>Ostatnio dostałeś/aś bardzo interesującą ofertę pracy. Muszę Cię zmartwić. Ta oferta to tylko sprytnie przygotowany atak szkoleniowy wykorzystujący socjotechnikę, tak jak to robią prawdziwi hakerzy. Atak polegał na zainteresowaniu Cię przedstawioną ofertą, tak abyś wykonał/ła dla atakującego pewne akcje. W tym przypadku chodziło nam o kliknięcie w link a następnie próbę pobrania załącznika, którego treści mogą być potencjalnie niebezpieczne. Poniżej znajduje się opis powyższych akcji wraz z informacją jak i Tobie poszło.</p>\r\n<p>Poniżej opisujemy poszczeg&oacute;lne etapu ataku, kt&oacute;ry był symulowany:</p>',
        awareness_mail_subject: 'WAŻNE: Informacja o wynikach symulacji ataku dla użytkownika %PERSON.FIRSTNAME%',
        severity: 'C',
        language: 'PL',
      },
    ]);
  }
}
