import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { RestObject } from './rest-framework';

export interface NormalGroup extends RestObject {
  name: string;
  members: number;
  group_type: string;
}

export abstract class NormalGroupsData extends RestFrameworkService<NormalGroup> {}
