import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { RestObject } from './rest-framework';
import { Persons } from './persons';
import { Parameter } from './parameters';

export interface Scenario extends RestObject {
  name: string;
  description: string;
  scenariotype: string;
  url: string;
  mail_subject: string;
  mail_body_male: string;
  mail_body_female: string;
  awareness_content: string;
  awareness_mail_subject: string;
  severity: string;
  language: string;
  parameters?: Parameter[];
}

export interface ScenarioResponse {
  status: string;
  info: string;
}

export interface NewCampaignRequest {
  uid: string;
  name: string;
}

export interface Objective extends RestObject {
  severity: string;
  description: string;
  count: number;
  rate: string;
}

export interface CampaignProgressResponse {
  sent_ok: number;
  sent_error: number;
  not_sent: number;
  edu_sent_ok: number;
  edu_sent_error: number;
  edu_not_sent: number;
  education: boolean;
}

export interface RecipientWithObjectives {
  uid: string;
  campaign_scenario_uid: string;
  person_uid: Persons[];
  hashtags: [string];
  mail_sent: boolean;
  edu_sent: boolean;
  status: string;
  objectives: Objective[];
}


export abstract class ScenarioData extends RestFrameworkService<Scenario> {

}
