import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { ListFetchOptions, RestObject } from './rest-framework';

export interface Parameter extends RestObject {
  name: string;
  value: string;
  description: string;
  paramtype: string;
  required: boolean;
  scenario_uid: string;
  campaign_scenario_uid: null;
}

export abstract class ParametersData extends RestFrameworkService<Parameter> {
  public abstract readParametersList(_: ListFetchOptions, uid: RestObject): Observable<Parameter | any>;
  public abstract loadAllParameters(uid: RestObject): Observable< any>;

}
