import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { C2ModulesRequest, C2ModulesData } from '../data/c2modules';
import { Observable } from 'rxjs';

@Injectable()
export class C2ModulesService extends C2ModulesData {
  constructor(public http: HttpClient) {
    super('c2modules', http);
  }

  public getModules(req: C2ModulesRequest): Observable<any> {
    return this.http.get<any>(
      this.url + this.endpoint + '/?payload_family=' + req.payload_family,
    );
  }
}
