import { Injectable } from '@angular/core';
import {
  EmailTestRequest,
  EmailTestResponse,
  EmailTestData,
} from '../data/email-test';
import { Observable, of } from 'rxjs';

@Injectable()
export class EmailTestMockService extends EmailTestData {
  constructor() {
    super('CHECK-MAIL-SERVER', []);
  }

  public test(req: EmailTestRequest): Observable<EmailTestResponse | any> {
    return of({
      status: 'success',
    });
  }
}
