import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { RestObject } from './rest-framework';

export interface Group extends RestObject {
  name: string;
  members: number;
  group_type: string;
}

export interface Task {
  name: string;
  completed: boolean;
  subtasks?: Task[];
}

export abstract class GroupData extends RestFrameworkService<Group> {

  public abstract createGroup(group: any, personUids: string[]): Observable<any>;

}
