import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbDialogService,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { LoggerService } from '../../../@core/utils/logger.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { DialogChangePasswordComponent } from './change-password/dialog-change-password.component';
import { CookieTempService } from '../../../@core/services/cookie.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: string;

  // themes = [
  //   {
  //     value: 'default',
  //     name: 'Light',
  //   },
  //   {
  //     value: 'dark',
  //     name: 'Dark',
  //   },
  //   {
  //     value: 'cosmic',
  //     name: 'Cosmic',
  //   },
  //   {
  //     value: 'corporate',
  //     name: 'Corporate',
  //   },
  // ];

  // currentTheme = 'default';
  // langSelect = '';
  userMenu = [{ title: 'Change password' }, { title: 'logout' }];

  private retranslate() {
    this.userMenu[0]['title'] =
      this.translate.instant('profile_menu.change_password');
    this.userMenu[1]['title'] =
      this.translate.instant('profile_menu.logout');
  }

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: NbAuthService,
    private log: LoggerService,
    private router: Router,
    public translate: TranslateService,
    private dialogService: NbDialogService,
    public cookieService: CookieTempService,
  ) {
    this.translate.setDefaultLang('en');
    // this.translate.addLangs(['pl', 'en']);
    // this.langSelect = this.translate.getBrowserLang();
    // this.translate.use(this.translate.getBrowserLang());
  }

  ngOnInit() {
    if (environment.use_mock)
      this.user = 'su';
    else {
      this.authService.getToken().subscribe((t: NbAuthJWTToken) => {
        if (t.isValid()) {
          this.user = t.getPayload()['user'];
        }
      });
      this.authService.onTokenChange().pipe(takeUntil(this.destroy$))
        .subscribe((token: NbAuthJWTToken) => {
          if (token.isValid()) {
            this.log.info('[HeaderComponent][onTokenChange] New token: ', token);
            this.user = token.getPayload()['user'];
          }
        });
    }
    // this.currentTheme = this.themeService.currentTheme;
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    // this.themeService.onThemeChange()
    //   .pipe(
    //     map(({name}) => name),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe(themeName => this.currentTheme = themeName);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.cookieService.theme = themeName);

    this.translate.onLangChange.pipe(
      takeUntil(this.destroy$),
    ).subscribe(_ => this.retranslate());
    this.menuService.onItemClick()
      .pipe(
        takeUntil(this.destroy$),
        filter(({ tag }) => tag === 'user-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if (this.userMenu.findIndex(e => e.title === title) === 0) {
          this.changePassword();
        } else {
          this.logout();
        }
      });
  }

  private logout() {
    this.log.info('[HeaderComponent][logout] Loggin out...');
    this.authService.logout('aptbait').pipe(
      takeUntil(this.destroy$),
    ).subscribe(result => {
      this.log.info('[HeaderComponent][logout] Result: ', result);
      if (result.isSuccess()) {
        this.router.navigate([result.getRedirect()]);
      }
    });
  }

  private changePassword() {
    this.dialogService.open(DialogChangePasswordComponent);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
    this.cookieService.saveTheme();
  }

  changeLanguage(language: string) {
    this.translate.use(language);
    this.cookieService.saveLanguage();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

}

// import { Component, OnDestroy, OnInit } from '@angular/core';
// import {
//   NbDialogService,
//   NbMediaBreakpointsService,
//   NbMenuService,
//   NbSidebarService,
//   NbThemeService,
// } from '@nebular/theme';

// import { LayoutService } from '../../../@core/utils';
// import { filter, map, takeUntil } from 'rxjs/operators';
// import { Subject } from 'rxjs';
// import { TranslateService } from '@ngx-translate/core';
// import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
// import { LoggerService } from '../../../@core/utils/logger.service';
// import { environment } from '../../../../environments/environment';
// import { Router } from '@angular/router';
// import { DialogChangePasswordComponent } from './change-password/dialog-change-password.component';

// @Component({
//   selector: 'ngx-header',
//   styleUrls: ['./header.component.scss'],
//   templateUrl: './header.component.html',
// })
// export class HeaderComponent implements OnInit, OnDestroy {

//   private destroy$: Subject<void> = new Subject<void>();
//   userPictureOnly: boolean = false;
//   user: string;

//   themes = [
//     {
//       value: 'default',
//       name: 'Light',
//     },
//     {
//       value: 'dark',
//       name: 'Dark',
//     },
//     {
//       value: 'cosmic',
//       name: 'Cosmic',
//     },
//     {
//       value: 'corporate',
//       name: 'Corporate',
//     },
//   ];

//   currentTheme = 'default';
//   langSelect = '';
//   userMenu = [{title: 'Change password'}, {title: 'logout'}];

//   private retranslate() {
//     this.userMenu[0]['title'] =
//       this.translate.instant('profile_menu.change_password');
//     this.userMenu[1]['title'] =
//       this.translate.instant('profile_menu.logout');
//   }

//   constructor(private sidebarService: NbSidebarService,
//               private menuService: NbMenuService,
//               private themeService: NbThemeService,
//               private layoutService: LayoutService,
//               private breakpointService: NbMediaBreakpointsService,
//               private authService: NbAuthService,
//               private log: LoggerService,
//               private router: Router,
//               public translate: TranslateService,
//               private dialogService: NbDialogService) {
//     this.translate.setDefaultLang('en');
//     this.translate.addLangs(['pl', 'en']);
//     this.langSelect = this.translate.getBrowserLang();
//     this.translate.use(this.translate.getBrowserLang());
//   }

//   ngOnInit() {
//     if (environment.use_mock)
//       this.user = 'su';
//     else {
//       this.authService.getToken().subscribe((t: NbAuthJWTToken) => {
//         if (t.isValid()) {
//           this.user = t.getPayload()['user'];
//         }
//       });
//       this.authService.onTokenChange().pipe(takeUntil(this.destroy$))
//         .subscribe((token: NbAuthJWTToken) => {
//           if (token.isValid()) {
//             this.log.info('[HeaderComponent][onTokenChange] New token: ', token);
//             this.user = token.getPayload()['user'];
//           }
//         });
//     }
//     this.currentTheme = this.themeService.currentTheme;
//     const {xl} = this.breakpointService.getBreakpointsMap();
//     this.themeService.onMediaQueryChange()
//       .pipe(
//         map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
//         takeUntil(this.destroy$),
//       )
//       .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

//     this.themeService.onThemeChange()
//       .pipe(
//         map(({name}) => name),
//         takeUntil(this.destroy$),
//       )
//       .subscribe(themeName => this.currentTheme = themeName);
//     this.translate.onLangChange.pipe(
//       takeUntil(this.destroy$),
//     ).subscribe(_ => this.retranslate());
//     this.menuService.onItemClick()
//       .pipe(
//         takeUntil(this.destroy$),
//         filter(({tag}) => tag === 'user-menu'),
//         map(({item: {title}}) => title),
//       )
//       .subscribe(title => {
//         if (this.userMenu.findIndex(e => e.title === title) === 0) {
//           this.changePassword();
//         } else {
//           this.logout();
//         }
//       });
//   }

//   private logout() {
//     this.log.info('[HeaderComponent][logout] Loggin out...');
//     this.authService.logout('aptbait').pipe(
//       takeUntil(this.destroy$),
//     ).subscribe(result => {
//       this.log.info('[HeaderComponent][logout] Result: ', result);
//       if (result.isSuccess()) {
//         this.router.navigate([result.getRedirect()]);
//       }
//     });
//   }

//   private changePassword() {
//     this.dialogService.open(DialogChangePasswordComponent);
//   }

//   ngOnDestroy() {
//     this.destroy$.next();
//     this.destroy$.complete();
//   }

//   changeTheme(themeName: string) {
//     this.themeService.changeTheme(themeName);
//   }

//   toggleSidebar(): boolean {
//     this.sidebarService.toggle(true, 'menu-sidebar');
//     this.layoutService.changeLayoutSize();

//     return false;
//   }

//   navigateHome() {
//     this.menuService.navigateHome();
//     return false;
//   }

// }
