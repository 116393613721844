export interface SupportRequest {
  mail: string;
  phone: number;
  subject: string;
  message: string;
  firstname: string;
  lastname: string;
}

export interface SupportResponse extends SupportRequest {
  uid?: string;
}

export abstract class SupportData {
  // abstract getUsers(): Observable<User[]>;
}
