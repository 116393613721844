import { Component } from '@angular/core';
import {
  NbDialogRef,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from '@nebular/theme';
import { LoggerService } from '../../../../@core/utils/logger.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  SessionChangePasswordResponse,
  SessionData,
} from '../../../../@core/data/session';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-dialog-change-password',
  templateUrl: 'dialog-change-password.component.html',
  styleUrls: ['dialog-change-password.component.scss'],
})
export class DialogChangePasswordComponent {
  public pending: boolean = false;
  public changePassForm: FormGroup;

  constructor(
    protected ref: NbDialogRef<DialogChangePasswordComponent>,
    protected log: LoggerService,
    private formBuilder: FormBuilder,
    private sessionService: SessionData,
    private toastrService: NbToastrService,
    private translate: TranslateService,
  ) {
    this.changePassForm = this.formBuilder.group({
      newpass: ['',
        {
          validators: [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&].{7,}'),
          ],
          updateOn: 'change',
        },
      ],
      newpass2: ['',
        {
          validators: [
            Validators.required,
          ],
          updateOn: 'change',
        },
      ],
      oldpass: ['',
        {
          validators: [
            Validators.required,
          ],
          updateOn: 'change',
        },
      ],
    }, {
      validators: [
        this.checkPasswordRepeated,
        this.checkSameAsOld,
      ],
      updateOn: 'change',
    });
  }

  cancel() {
    this.ref.close();
  }

  changePassword() {
    this.pending = true;
    const json = JSON.parse(JSON.stringify(this.changePassForm.value));
    delete json['newpass2'];
    this.log.debug('[DialogChangePasswordComponent][changePassword] Form', json);
    this.sessionService.changePassword(json).subscribe(
      (resp: SessionChangePasswordResponse) => {
        if (resp.status === 'success') {
          const config = {
            status: 'success',
            destroyByClick: true,
            duration: 5000,
            hasIcon: true,
            position: NbGlobalPhysicalPosition.TOP_RIGHT,
            preventDuplicates: false,
          };
          this.toastrService.show(
            this.translate.instant('change_password.toas_title_message_success'),
            this.translate.instant('change_password.toas_title_success'),
            config);
          this.ref.close(name);
        } else {
          const config = {
            status: 'danger',
            destroyByClick: true,
            duration: 5000,
            hasIcon: true,
            position: NbGlobalPhysicalPosition.TOP_RIGHT,
            preventDuplicates: false,
          };
          this.toastrService.show(
            this.translate.instant('change_password.toas_title_message_failure'),
            this.translate.instant('change_password.toas_title_failure'),
            config);
        }
        this.pending = false;
      },
    );
  }

  checkPasswordRepeated(group: FormControl) {
    const newpass = group.get('newpass').value;
    const newpass2 = group.get('newpass2').value;

    return newpass === newpass2 ? null : {checkPasswordRepeated: true};
  }

  checkSameAsOld(group: FormControl) {
    const newpass = group.get('newpass').value;
    const old = group.get('oldpass').value;

    return newpass !== old ? null : {checkSameAsOld: true};
  }
}
