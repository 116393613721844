import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IpFilteringSettingsData } from '../data/ip-filtering-settings';

@Injectable()
export class IpFilteringSettingsMockService extends IpFilteringSettingsData {
  constructor() {
    super('IP-FILTERING', [
      {
        uid: 'e004a286-c4c9-466d-9eff-5ce600bd217f',
        ip: '100.119.111.176',
        description: 'Test1',
        net_mask: 32,
      },
      {
        uid: 'e004a286-c4c9-466d-9eff-5ce600bd217f',
        ip: '100.129.110.176',
        description: 'Test2',
        net_mask: 16,
      },
      {
        uid: 'e004a286-c4c9-466d-9eff-5ce600bd217f',
        ip: '41.119.110.176',
        description: 'Test3',
        net_mask: 32,
      },
      {
        uid: 'e004a286-c4c9-466d-9eff-5ce600bd217f',
        ip: '100.119.11.176',
        description: 'Test4',
        net_mask: 8,
      },
      {
        uid: 'e004a286-c4c9-466d-9eff-5ce600bd217f',
        ip: '10.11.11.17',
        description: 'Test5',
        net_mask: 32,
      },
      {
        uid: 'e004a286-c4c9-466d-9eff-5ce600bd217f',
        ip: '100.119.110.176',
        description: 'Test6',
        net_mask: 32,
      },
    ]);
  }
}
