import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  EduLogo,
  ResetResponse,
  FileUploadResponse,
  EduLogoData,
} from '../data/edulogo';

@Injectable()
export class EduLogoService extends EduLogoData {
  constructor(public http: HttpClient) {
    super('edulogo', http);
  }

  public get(): Observable<EduLogo | any> {
    return this.http.get<EduLogo>(this.url + this.endpoint + '/');
  }

  public reset(): Observable<ResetResponse | any> {
    return this.http.delete<ResetResponse>(this.url + this.endpoint + '/');
  }

  public upload(req: File): Observable<FileUploadResponse | any> {
    const endpoint = this.url + this.endpoint + '/';
    const formData: FormData = new FormData();
    formData.append('file', req, req.name);
    return this.http.post<FileUploadResponse>(endpoint, formData);
  }
}
