import { Injectable } from '@angular/core';
import { VictimsData } from '../data/victim';
import { ListFetchOptionsForVictim } from '../data/rest-framework';
import { Observable, of } from 'rxjs';
import { Victim } from '../data/victim';

@Injectable()
export class VictimMockService extends VictimsData {
  constructor() {
    super('VICTIM', [
      {
        uid: 'a68e76d0-c798-452c-98a0-1a905a7b8a25',
        recipient_uid: {
          uid: 'cccff5ac-a14a-4419-8b51-ba7c63d6f796',
          campaign_scenario_uid: '711ed741-f048-4636-a735-e27f47b0ac0d',
          person_uid: {
            uid: '4af08275-9f99-4d21-92b9-2cc010815a0b',
            firstname: 'Dejv',
            lastname: 'Cieślak',
            isMale: true,
            position: 'Dev',
            email: 'dawio34@o2.pl',
            phone: '734172886',
            comment: 'xDDD',
          },
          hashtags: [],
        },
        ip: '94.240.24.165',
        username: 'dawid',
        domain: null,
        os: 'Microsoft Windows 10 Education',
        connected_time: '2021-03-31T08:42:16.383856Z',
        last_activity: '2021-04-02T11:05:05.701645Z',
        payload_family: 'windows/powershell',
        hashtags: [],
        icons: ['windows10.png', 'powershell.png'],
        campaign_scenario_uid: '711ed741-f048-4636-a735-e27f47b0ac0d',
      },
      {
        uid: '80c74f6d-1318-4649-b5bf-815ef17691d4',
        recipient_uid: {
          uid: 'cccff5ac-a14a-4419-8b51-ba7c63d6f796',
          campaign_scenario_uid: '38062164-9aad-4635-a167-ce0b2d7acb5b',
          person_uid: {
            uid: '4af08275-9f99-4d21-92b9-2cc010815a0b',
            firstname: 'Dejv',
            lastname: 'Cieślak',
            isMale: true,
            position: 'Dev',
            email: 'dawio34@o2.pl',
            phone: '734172886',
            comment: 'xDDD',
          },
          hashtags: [],
        },
        ip: '94.240.24.165',
        username: 'dawid',
        domain: null,
        os: 'Microsoft Windows 10 Education',
        connected_time: '2021-03-30T20:29:27.263325Z',
        last_activity: '2021-04-02T11:05:05.700338Z',
        payload_family: 'windows/powershell',
        hashtags: [],
        icons: ['windows10.png', 'powershell.png'],
        campaign_scenario_uid: '38062164-9aad-4635-a167-ce0b2d7acb5b',
      },
      {
        uid: 'fdad5821-d216-4757-8962-32afdf180dd4',
        recipient_uid: {
          uid: '8a8497f3-ae2a-4fab-a50f-fef2196732d6',
          campaign_scenario_uid: '38062164-9aad-4635-a167-ce0b2d7acb5b',
          person_uid: {
            uid: '96c25a97-7301-45b1-b858-30951f3229ad',
            firstname: 'Dejv',
            lastname: 'Cieślak',
            isMale: true,
            position: 'Głąb2',
            email: 'rycerz30.06@o2.pl',
            phone: null,
            comment: null,
          },
          hashtags: [],
        },
        ip: '94.240.24.165',
        username: 'Dejv Cieślak',
        domain: null,
        os: 'Microsoft Windows 10 Education',
        connected_time: '2021-03-30T20:31:07.578550Z',
        last_activity: '2021-03-30T20:34:30.060525Z',
        payload_family: 'windows/powershell',
        hashtags: [],
        icons: ['windows10.png', 'powershell.png'],
        campaign_scenario_uid: '38062164-9aad-4635-a167-ce0b2d7acb5b',
      },
      {
        uid: '759a74bb-abb5-49ef-a68e-daa1b5460837',
        recipient_uid: {
          uid: 'd6e86d73-1a17-4a9b-a5c3-771e17d245d1',
          campaign_scenario_uid: '1f0b968c-7407-486c-8221-ecf37ac9c74e',
          person_uid: {
            uid: '54a311b1-1375-4eed-af7f-7d35760daa47',
            firstname: 'dsadad',
            lastname: 'dsad',
            isMale: true,
            position: null,
            email: 'dawio34@o2.pl',
            phone: null,
            comment: null,
          },
          hashtags: [],
        },
        ip: '85.222.106.182',
        username: null,
        domain: null,
        os: null,
        connected_time: '2021-03-04T09:32:25.595363Z',
        last_activity: '2021-03-08T10:03:36.218381Z',
        payload_family: 'windows/powershell',
        hashtags: [],
        icons: ['windows10.png', 'powershell.png'],
        campaign_scenario_uid: '1f0b968c-7407-486c-8221-ecf37ac9c74e',
      },
    ]);
  }
  public readVictimList(
    options: ListFetchOptionsForVictim,
  ): Observable<Victim | any> {
    let d: Array<Victim> = this.data as Array<Victim>;
    const tmp: Array<Victim> = [];
    let out: Array<Victim> = [];
    if (options.search) {
      d = d.filter((v) => {
        let search = '';
        Object.keys(v).forEach((key) => {
          search += ' ' + v[key];
        });
        return search.indexOf(options.search) !== -1;
      });
    }
    const page: number = options.page ? options.page : 1;
    const page_size: number = options.page_size ? options.page_size : 20;
    if (options.filter === 'all') {
    } else if (options.filter === 'other') d = [];
    else {
      d.forEach((vic) => {
        if (vic.campaign_scenario_uid === options.filter) {
          tmp.push(vic);
        }
      });
      out = tmp.slice(
        (page - 1) * page_size,
        (page - 1) * page_size + page_size,
      );
      return of({
        next: null,
        previous: null,
        count: d.length,
        max_page_size: 100,
        results: out,
      });
    }
    out = d.slice((page - 1) * page_size, (page - 1) * page_size + page_size);
    return of({
      next: null,
      previous: null,
      count: d.length,
      max_page_size: 100,
      results: out,
    });
  }
}
