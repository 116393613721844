import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { RestObject } from './rest-framework';

export interface EmailTestRequest {
  host: string;
  port: string;
  encryption: string;
  login: string;
  password: string;
  sender_name: string;
  sender_email: string;
  testbox: string;
}

export interface EmailTestResponse {
  status: string;
}

export abstract class EmailTestData extends RestFrameworkService<{}> {
  public abstract test(_: EmailTestRequest): Observable<EmailTestResponse | any>;
}
