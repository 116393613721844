import { RestFrameworkService } from '../services/rest-framework-service';
import { RestObject } from './rest-framework';

export interface CountryFiltering extends RestObject {
  uid?: string;
  prefix?: string;
  status: boolean;
}

export abstract class CountryFilteringSettingsData extends RestFrameworkService<CountryFiltering> {}
