<span class="created-by">
    Created with ♥ by <b><a href="/https://mysza.software/" target="_blank">Mysza Software</a></b> 2021
</span>
    <div *ngIf="cookieService.showCookiePromt">
        <span class="text-small">
            {{ 'footer.cookie-policy' | translate }}
        </span>
    </div>
    <div *ngIf="cookieService.showCookiePromt">
        <button nbButton [status]="buttonStatuse" outline (click)="acceptCookie()">{{ 'footer.accept' | translate }}</button>
    </div>
<div class="socials">
    <a href="#" target="_blank" class="ion ion-social-facebook"></a>
    <a href="#" target="_blank" class="ion ion-social-twitter"></a>
    <a href="https://www.linkedin.com/company/aptdefend/about/" target="_blank" class="ion ion-social-linkedin"></a>
</div>