import { Injectable } from '@angular/core';
import { CountryFilteringSettingsData } from '../data/country-filtering-settings';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CountryFilteringSettingsService extends CountryFilteringSettingsData {
  constructor(public http: HttpClient) {
    super('country', http);
  }
}
