import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SettingsIntervalsData, Intervals } from '../data/settings-intervals';

@Injectable()
export class SettingsIntervalsMockService extends SettingsIntervalsData {
  constructor() {
    super('mailing-interval', [
      {
        uid: '5b1efc0d-7d19-4504-a5cf-98bd5fd0ac92',
        emails_per_pack: 100,
        emails_delay: 20000,
        pack_delay: 600000,
      },
      {
        uid: 'c0aacc8d-5bd6-43c5-9397-2fe1b9fb3a0f',
        emails_per_pack: 13,
        emails_delay: 394,
        pack_delay: 379,
      },
      {
        uid: 'c88b7c9e-71d5-4b55-a3a5-26a14337ff00',
        emails_per_pack: 5,
        emails_delay: 2957,
        pack_delay: 9655,
      },
      {
        uid: '4030aed0-8e3f-438b-a1f9-940ec23147eb',
        emails_per_pack: 3,
        emails_delay: 20000,
        pack_delay: 600000,
      },
      {
        uid: '4030aed0-8e3f-438b-a1f9-940ec23147ef',
        emails_per_pack: 3,
        emails_delay: 20000,
        pack_delay: 600000,
      },
      {
        uid: '4030aed0-8e3f-438b-a1f9-940ec23147es',
        emails_per_pack: 3,
        emails_delay: 20000,
        pack_delay: 600000,
      },
      {
        uid: '4030aed0-8e3f-438b-a1f9-940ec23147ea',
        emails_per_pack: 3,
        emails_delay: 20000,
        pack_delay: 600000,
      },
    ]);
  }
}
