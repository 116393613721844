import { Injectable } from '@angular/core';
import {
  PersonsData,
  PersonsUploadResponse,
} from '../data/persons';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GroupData } from '../data/group';

@Injectable()
export class GroupService extends GroupData {

  constructor(public http: HttpClient) {
    super('group', http);
  }

  public createGroup(group: any, personUids: any): Observable<any> {
    // tworzenie grupy razem z tworzeniem GroupPerson
    return (this.data as HttpClient).request<any>('post',
      this.url + this.endpoint + '/create_group/',
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: {
          'group': group,
          'uuids': personUids,
        },
      },
    );
  }
}
