import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';

export interface EduLogo {
  current: string;
  default: string;
  isdefault: boolean;
}

export interface ResetResponse {
  status: string;
}

export interface FileUploadResponse {
  status: string;
  reason?: object;
}

export abstract class EduLogoData extends RestFrameworkService<{}> {
  public abstract get(): Observable<EduLogo | any>;
  public abstract reset(): Observable<ResetResponse | any>;
  public abstract upload(_: File): Observable<FileUploadResponse | any>;
}
