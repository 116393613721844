import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { RestObject } from './rest-framework';

export interface SessionChangePasswordRequest {
  newpass: string;
  oldpass: string;
}

export interface SessionChangePasswordResponse {
  status: string;
}

export abstract class SessionData extends RestFrameworkService<{}> {
  public abstract changePassword(_: SessionChangePasswordRequest): Observable<SessionChangePasswordResponse | any>;
}
