import { Injectable } from '@angular/core';
import { FilterSettingData, FilterSetting } from '../data/filter-setting';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class FilterSettingService extends FilterSettingData {
  constructor(public http: HttpClient) {
    super('ip-filter-setting', http);
  }

  public get(): Observable<FilterSetting | any> {
    return this.http.get<FilterSetting>(this.url + this.endpoint + '/');
  }
}
