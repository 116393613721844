import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';

export interface C2ModulesRequest {
  payload_family: string;
  os?: string;
}

export abstract class C2ModulesData extends RestFrameworkService<{}> {
  public abstract getModules(_: C2ModulesRequest): Observable<any>;
}
