import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'severityLevel' })
export class SeverityPipe implements PipeTransform {
  transform(severity: string): string {
    let text = '';
    switch (severity) {
      case 'C': text = 'Critical'; break;
      case 'H': text = 'High'; break;
      case 'M': text = 'Medium'; break;
      case 'L': text = 'Low'; break;
    }
    return text;
  }
}
