import { environment } from '../../../environments/environment';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { LogLevel, Message } from '../typedefs-n-enums';

export interface LoggerEngine {
  debug(...args: any[]): void;

  info(...args: any[]): void;

  trace(...args: any[]): void;

  log(...args: any[]): void;

  warn(...args: any[]): void;

  error(...args: any[]): void;
}

/* eslint-disable */
/* tslint:disable */
export const consoleEngine: LoggerEngine = {
  log: console.log.bind(console),
  warn: console.warn.bind(console),
  error: console.error.bind(console),
  debug: console['debug'].bind(console),
  info: console['info'].bind(console),
  trace: console['trace'].bind(console),
};
/* eslint-enable */
/* tslint:enable */

export const LOGGER_ENGINE = new InjectionToken<LoggerEngine>('Logger engine');

@Injectable({
  providedIn: 'root',
})
export class LoggerService implements LoggerEngine {
  // Min log level to write log
  // logs with lower level than this will be ignored
  private level: LogLevel = environment.debug ? LogLevel.DEBUG : LogLevel.WARN;
  private last = +new Date();

  constructor(@Inject(LOGGER_ENGINE) private engine: LoggerEngine) {
    window['logger'] = this;
  }

  public error(...args: any[]): void {
    this.write(LogLevel.ERROR, ...args);
  }

  public log(...args: any[]): void {
    this.write(LogLevel.LOG, ...args);
  }

  public warn(...args: any[]): void {
    this.write(LogLevel.WARN, ...args);
  }

  public debug(...args: any[]): void {
    this.write(LogLevel.DEBUG, ...args);
  }

  public info(...args: any[]): void {
    this.write(LogLevel.INFO, ...args);
  }

  public trace(...args: any[]): void {}

  onLogChunk(chunk: Message | string) {
    if (typeof chunk === 'string') {
      this.log(chunk);
    } else {
      this.write(chunk.level, ...chunk.message);
    }
  }

  write(level: LogLevel, ...targets: any[]): void {
    if (level < this.level) {
      return;
    }
    targets.splice(0, 0, '<LoggerService>');
    const time = +new Date() - this.last;
    this.last = +new Date();
    switch (level) {
      case LogLevel.DEBUG:
        return this.engine.debug(`[+${time}ms]`, ...targets);
      case LogLevel.INFO:
        return this.engine.info(`[+${time}ms]`, ...targets);
      case LogLevel.TRACE:
        return this.engine.trace(`[+${time}ms]`, ...targets);
      case LogLevel.WARN:
        return this.engine.warn(`[+${time}ms]`, ...targets);
      case LogLevel.ERROR:
        return this.engine.error(`[+${time}ms]`, ...targets);
      case LogLevel.LOG:
      default:
        return this.engine.log(`[+${time}ms]`, ...targets);
    }
  }
}
