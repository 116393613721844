import { Injectable } from '@angular/core';
import { EmailAccountSettingsData } from '../data/email-account-settings';

@Injectable()
export class EmailAccountSettingsMockService extends EmailAccountSettingsData {
  constructor() {
    super('EMAIL-ACCOUNT', [
      {
        uid: '32d8c297-a811-4c44-9787-3b4f5c6d97f9',
        description: 'rekruteka.pl',
        host: 'da27.domeny.com',
        port: 587,
        encryption: 'T',
        login: 'iwona.bober@rekruteka.pl',
        password: 'iwonka',
        sender_name: 'Iwona Bober',
        sender_email: 'iwona.bober@rekruteka.pl',
        ignore_certyficate: true,
      },
      {
        uid: '88ef430c-b277-40d0-9d32-3c74fefcc820',
        description:
          'Mail security@officecloud24.com uzywany w scenaruszu Office365 - niebezpieczny załącznik',
        host: 'da27.domeny.com',
        port: 587,
        encryption: 'T',
        login: 'iwona.bober@rekruteka.pl',
        password: 'iwonka',
        sender_name: null,
        sender_email: 'security@officecloud24.com',
        ignore_certyficate: true,
      },
      {
        uid: '04ede6fc-bee2-4e9a-970e-28f2fdbddeeb',
        description: 'aaxxx',
        host: 'nbn',
        port: 587,
        encryption: 'T',
        login: 'iwona.bober@rekruteka.pl',
        password: 'iwonka',
        sender_name: null,
        sender_email: 'xxx',
        ignore_certyficate: true,
      },
      {
        uid: '4121645b-e404-4a58-a006-c08c2b1eea53',
        description: 'Rekruteka@Onet',
        host: 'poczta22618.domeny.host',
        port: 587,
        encryption: 'T',
        login: 'iwona.bober@rekruteka.pl',
        password: 'iwonka',
        sender_name: 'Woletta Zamojska',
        sender_email: 'wioletta.zamojska@rekruteka.pl',
        ignore_certyficate: true,
      },
      {
        uid: '7a86e38d-8b52-430b-bbe5-65fb6c4535aa',
        description: 'Rekruteka MA Polska',
        host: 'poczta22618.domeny.host',
        port: 587,
        encryption: 'T',
        login: 'iwona.bober@rekruteka.pl',
        password: 'iwonka',
        sender_name: 'Zuzanna Jaworska',
        sender_email: 'zuzanna.jaworska@rekruteka.pl',
        ignore_certyficate: true,
      },
      {
        uid: '18f2c790-46d6-4937-9eb5-97e3495533c0',
        description: 'dsad',
        host: '123.123.123.123',
        port: 587,
        encryption: 'T',
        login: 'iwona.bober@rekruteka.pl',
        password: 'iwonka',
        sender_name: 'sda',
        sender_email: 'dsad',
        ignore_certyficate: true,
      },
    ]);
  }
}
