import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'levelClass' })
export class LevelClassPipe implements PipeTransform {
  transform(element: string): string {
      const classes = {
        'C': 'text-danger',
        'H': 'text-danger',
        'M': 'text-warning',
        'L': 'text-success',
    };
    return classes[element] || 'Unknown';
  }
}
