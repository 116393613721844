import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VictimsData } from '../data/victim';
import { ListFetchOptionsForVictim } from '../data/rest-framework';
import { Observable } from 'rxjs';
import { Victim } from '../data/victim';

@Injectable()
export class VictimService extends VictimsData {
  constructor(public http: HttpClient) {
    super('victim', http);
  }

  public readVictimList(
    options: ListFetchOptionsForVictim,
  ): Observable<Victim | any> {
    if (this.isMock()) {
      return this.mockReadList(options);
    }
    let url = `${this.url}${this.endpoint}/?`;
    if (options.filter === 'all') url += ``;
    else if (options.filter === 'other') url += `nocampaign=1`;
    else
      url += `&recipient_uid__campaign_scenario_uid__campaign_uid=${options.filter}`;
    if (options.page) url += `&page=${options.page}`;
    if (options.page_size) url += `&page_size=${options.page_size}`;
    if (options.search) url += `&search=${options.search}`;
    if (options.ordering) url += `ordering=${options.ordering}`;
    return (this.data as HttpClient).get<Victim>(url);
  }
}
