import { Injectable } from '@angular/core';
import { ObjectivesData, Objective } from '../data/objectives';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ListFetchOptions, RestFrameworkListResponse, RestObject } from '../data/rest-framework';

@Injectable()
export class ObjectivesService extends ObjectivesData {

  constructor(public http: HttpClient) {
    super('objective', http);
  }

  public readObjectivesList(
    options: ListFetchOptions, uid: RestObject ): Observable<Objective | any> {
    let url = `${this.url}${this.endpoint}/?`;
    if (options.page) url += `&page=${options.page}`;
    if (options.page_size) url += `&page_size=${options.page_size}`;
    if (options.search) url += `&search=${options.search}`;
    if (options.ordering) url += `ordering=${options.ordering}`;
    url += `&scenario_uid=${uid}`;
    return (this.data as HttpClient).get<RestFrameworkListResponse<Objective>>(url);
  }
}
