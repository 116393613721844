import { Injectable } from '@angular/core';
import { Persons } from '../data/persons';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListFetchOptions, RestFrameworkListResponse, RestObject } from '../data/rest-framework';
import { StatisticsUsersData } from '../data/statistics-users';

@Injectable()
export class StatisticsUsersService extends StatisticsUsersData {

  constructor(public http: HttpClient) {
    super('statsusers', http);
  }

  public readPersonsStatistics(
    options: ListFetchOptions, type: string ): Observable<Persons | any> {
    let url = `${this.url}${this.endpoint}/?`;
    if (options.page) url += `&page=${options.page}`;
    if (options.page_size) url += `&page_size=${options.page_size}`;
    url += `&type=${type}`;
    return (this.data as HttpClient).get<RestFrameworkListResponse<Persons>>(url);
  }
}
