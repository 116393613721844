import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LicenseResponse, LicenseData } from '../data/license';
import { Observable, of } from 'rxjs';

@Injectable()
export class LicenseService extends LicenseData {

  constructor(public http: HttpClient) {
    super('license', http);
  }

  public getLicense(): Observable<LicenseResponse | any> {
    return this.http.get<LicenseResponse>(this.url + this.endpoint + '/');
  }

}

