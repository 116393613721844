import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { RestObject } from './rest-framework';

export interface Event extends RestObject {
  timestamp: string;
  recipient_code: string;
  objective_code: string;
  ip: string;
  useragent: string;
  details: string;
  severity: string;
  campaign_scenario_uid: string;
  objective_description: string;
  objective_uid: string;
  recipient_uid: string;
  country: string;
  filtered: boolean;
  recipient_name: string;
  recipient_email: string;
  scenario_name: string;
  scenario_description: string;
  campaign_name: string;
  campaign_description: string;
}

export abstract class EventsData extends RestFrameworkService<Event> {}
