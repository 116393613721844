import { RestFrameworkService } from '../services/rest-framework-service';
import { RestObject } from './rest-framework';
import { ListFetchOptionsForVictim } from './rest-framework';
import { Observable } from 'rxjs';

export interface Cmd extends RestObject {
  uid?: string;
  victim_uid: string;
  cmd?: string;
  result?: string;
  status: string;
  sent_date?: string;
  result_date?: string;
  cmdtype: string;
  module?: string;
  variables?: string;
}

export abstract class CmdData extends RestFrameworkService<Cmd> {
  public abstract readCmdList(
    _: ListFetchOptionsForVictim,
  ): Observable<Cmd | any>;
}
