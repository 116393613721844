import { Injectable } from '@angular/core';
import { EventsData, Event } from '../data/events';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EventsService extends EventsData {

  constructor(public http: HttpClient) {
    super('event', http);
  }
}
