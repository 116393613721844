import { of as observableOf, Observable } from 'rxjs';
import { Injectable, Type } from '@angular/core';
import { SettingsIntervalsData, Intervals } from '../data/settings-intervals';
import { NormalGroupsData, NormalGroup } from '../data/normal-groups';
import { ListFetchTypeOptions, RestFrameworkListResponse } from '../data/rest-framework';
import { GroupData } from '../data/group';
import { PersonsData } from '../data/persons';

@Injectable()
export class GroupsMockService extends NormalGroupsData {

    public readListType(options: ListFetchTypeOptions): Observable<RestFrameworkListResponse<GroupData> | any> {
        const dt: Array<any> = this.data as Array<any>;
        let d: Array<any> = [];
        if (options.type !== null && options.type !== '') {
        }
        for (let i = 0; i < dt.length; i++) {
            if (dt[i].group_type === options.type) d.push(dt[i]);
        }
        if (options.search) {
            d = d.filter((v) => {
                let search = '';
                Object.keys(v).forEach((key) => {
                    search += ' ' + v[key];
                });
                return search.indexOf(options.search) !== -1;
            });
        }

        const page: number = options.page ? options.page : 1;
        const page_size: number = options.page_size ? options.page_size : 20;
        const out = d.slice(
            (page - 1) * page_size,
            (page - 1) * page_size + page_size,
        );
        return observableOf({
            next: null,
            previous: null,
            count: d.length,
            max_page_size: 100,
            results: out,
        });
    }

    constructor() {
        super('groups', [
            {
                uid: '4d0500fe-eb07-4acb-8539-010d8aa88d20',
                name: '01',
                members: 2,
                group_type: 'S',
            },
            {
                uid: '2a753f22-026a-41cd-ae1d-1a4394e3d7a1',
                name: '02',
                members: 0,
                group_type: 'S',
            },
            {
                uid: 'e8413c0b-ae15-4683-b1a6-2db1584a32e8',
                name: '0bb',
                members: 0,
                group_type: 'S',
            },
            {
                uid: '6e5a9012-fa56-4a04-8364-1cb44c2a88fc',
                name: '111',
                members: 0,
                group_type: 'S',
            },
            {
                uid: 'bed39469-f274-4fd9-a8d0-f366d9e86499',
                name: 'Dział Bezpieczeństwa',
                members: 0,
                group_type: 'S',
            },
            {
                uid: '01f45d50-404d-45b5-b357-ae341dbdea4d',
                name: 'groupA',
                members: 6,
                group_type: 'S',
            },
            {
                uid: '1f28a94e-e921-43c5-8208-765c4ffe407a',
                name: 'groupB',
                members: 5,
                group_type: 'S',
            },
            {
                uid: '8b6baed7-fa39-470c-bdc9-9804a01b2faa',
                name: 'Kasia Gaweł group',
                members: 0,
                group_type: 'S',
            },

            {
                uid: '5b1efc0d-7d19-4504-a5cf-98bd5fd0ac92',
                name: '10 pracowników z najniższym scorem',
                members: 10,
                group_type: 'E',
            },
            {
                uid: 'c0aacc8d-5bd6-43c5-9397-2fe1b9fb3a0f',
                name: '10 pracowników z najniższym scorem',
                members: 9,
                group_type: 'E',
            },
            {
                uid: 'c88b7c9e-71d5-4b55-a3a5-26a14337ff00',
                name: '10 pracowników z najniższym scorem',
                members: 8,
                group_type: 'E',
            },
            {
                uid: '4030aed0-8e3f-438b-a1f9-940ec23147eb',
                name: '10 pracowników z najniższym scorem',
                members: 7,
                group_type: 'E',
            },
            {
                uid: '4030aed0-8e3f-438b-a1f9-940ec23147ef',
                name: '10 pracowników z najniższym scorem',
                members: 6,
                group_type: 'E',
            },
            {
                uid: '4030aed0-8e3f-438b-a1f9-940ec23147es',
                name: '10 pracowników z najniższym scorem',
                members: 10,
                group_type: 'E',
            },
        ]);
    }
}
