import { Injectable } from '@angular/core';
import { ParametersData, Parameter } from '../data/parameters';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ListFetchOptions, RestFrameworkListResponse, RestObject } from '../data/rest-framework';

@Injectable()
export class ParametersService extends ParametersData {

  constructor(public http: HttpClient) {
    super('parameter', http);
  }

  public readParametersList(
    options: ListFetchOptions, uid: RestObject ): Observable<Parameter | any> {
    let url = `${this.url}${this.endpoint}/?`;
    if (options.page) url += `&page=${options.page}`;
    if (options.page_size) url += `&page_size=${options.page_size}`;
    if (options.search) url += `&search=${options.search}`;
    if (options.ordering) url += `ordering=${options.ordering}`;
    url += `&scenario_uid=${uid}`;
    return (this.data as HttpClient).get<RestFrameworkListResponse<Parameter>>(url);
  }

  public loadAllParameters(uid): Observable< any> {
    return this.http.get(`${this.url}${this.endpoint}/?campaign_scenario_uid=${uid}`);
  }
}
