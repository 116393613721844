import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';

export interface FilterSetting {
  uid?: string;
  ip_black_list_filter?: boolean;
  countries_filter?: boolean;
  support_center?: boolean;
}

export abstract class FilterSettingData extends RestFrameworkService<FilterSetting> {
  public abstract get(): Observable<FilterSetting | any>;
}
