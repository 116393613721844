import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RestObject, ListFetchOptions } from '../data/rest-framework';
import { Objective } from '../data/campaigns';
import { ParametersData, Parameter } from '../data/parameters';

@Injectable()
export class ParametersMockService extends ParametersData {
  constructor() {
    super('PARAMETERS', [
      {
        uid: 'cdc06c5c-8b11-4170-a558-ab93a7f2fb8a',
        name: 'ewelina',
        value: 'q',
        description: 'Uruchomienie makra',
        required: true,
        scenario_uid: '4db5464f-007c-4087-bf6a-b45f6c273419',
        paramtype: 'T',
        campaign_scenario_uid: null,
      },
    ]);
  }

  public readParametersList( options: ListFetchOptions, url: RestObject): Observable<Parameter | any> {
    return of({
      count: 1,
      max_page_size: 200,
      next: null,
      previous: null,
      results: this.data as Array<Parameter>,
    });
  }


  public loadAllParameters(uid: RestObject): Observable< any> {
    return of({
      count: 1,
      max_page_size: 200,
      next: null,
      previous: null,
      results: this.data as Array<Parameter>,
    });
  }

}
