import { RestFrameworkService } from '../services/rest-framework-service';
import { RestObject } from './rest-framework';

export interface IpFiltering extends RestObject {
  uid?: string;
  ip: string;
  description: string;
  net_mask: number;
}

export abstract class IpFilteringSettingsData extends RestFrameworkService<IpFiltering> {}
