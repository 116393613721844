import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'scenarioType' })
export class ScenarioTypePipe implements PipeTransform {
  transform(type: string): string {
      const types = {
        'E': 'Email',
        'S': 'SMS',
        'F': 'Flash drive',
        'H': 'HID',
        'W': 'WiFi',
    };
    return types[type] || 'Unknown';
  }
}
