import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { RestObject } from './rest-framework';
import { ListFetchOptionsForVictim } from './rest-framework';

export interface Person {
  uid?: string;
  firstname: string;
  lastname: string;
  isMale: boolean;
  position: string;
  email: string;
  phone: string;
  comment: string;
}

export interface Recipient {
  uid?: string;
  campaign_scenario_uid: string;
  person_uid: Person;
  hashtags: [];
}

export interface Victim extends RestObject {
  uid?: string;
  recipient_uid: Recipient;
  ip: string;
  username: string;
  domain: string;
  os: string;
  connected_time: string;
  last_activity: string;
  payload_family: string;
  hashtags: [];
  icons: Array<string>;
  campaign_scenario_uid: string;
}

export abstract class VictimsData extends RestFrameworkService<Victim> {
  public abstract readVictimList(
    _: ListFetchOptionsForVictim,
  ): Observable<Victim | any>;
}
