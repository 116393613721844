import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { RestObject } from './rest-framework';

export interface PersonGroup extends RestObject {
  next: any;
  previous: any;
  count: number;
  max_page_size: number;
  results: PersonGroupResult[];
}

export interface PersonGroupResult {
  uid: string;
  group_uid: string;
  person_uid: string;
}

export abstract class PersonGroupData extends RestFrameworkService<PersonGroup> {

  public abstract educateMany(_: string[]): Observable<any>;

  public abstract createMany(uid: string, _: string[]): Observable<any>;

  public abstract groupDelete(uid: string, _: string[]): Observable<any>; // Delete by person and group uid

  public abstract getGroupPersons(uid: string): string[];

}
