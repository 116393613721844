import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  ObjectivesData,
} from '../data/objectives';
import { of } from 'rxjs';
import { RestObject, ListFetchOptions } from '../data/rest-framework';
import { Objective } from '../data/objectives';

@Injectable()
export class ObjectivesMockService extends ObjectivesData {

  constructor() {
    super('OBJECTIVES', [
      {
        uid: '7e75370c-45d4-4ee6-a7f3-c56c70797f76',
        code: 'execute',
        severity: 'C',
        description: 'Uruchomienie makra',
        scenario_uid: '4db5464f-007c-4087-bf6a-b45f6c273419',
        awareness_content: '<p><strong>Uruchomienie makra</strong></p>\r\n<p>Po wejściu na link przeglądarka rozpoczynała pobieranie dokumentu przeznaczonego dla oprogramowania Microsoft Office. W dokumencie tym zamiast prawdziwej oferty pracy był sprytny program napisany jako makro. Uruchomienie makra w pliku pochodzącym z nieznanego i niezaufanego źr&oacute;dła to bardzo lekkomyślne zachowanie. W tym przypadku taki użytkownik sam prosi się o kłopoty. Uruchomienie makra bowiem oznacza, że pozwalamy atakującemu na wykonanie dowolnej komendy, bądź serii komend na naszym komputerze. W praktyce w momencie, kiedy dopuścisz aby czyjeś makro uruchomiło się na Twoim komputerze, komputer przestaje być bezpieczny. Może na przykład zapisywać Twoje hasła, strony kt&oacute;re odwiedzasz czy nawet modyfikować ich zawartość. Każdy atakujący czeka na taką okazję. W większości przypadk&oacute;w kończy się to przejęciem kontroli nad Twoim komputerem przez atakującego. Teraz to on ma nad Twoim komputerem pełną kontrolę, nie Ty. Nigdy nie dopuść do tego aby nasza firma mogła być zaatakowana w tak prosty spos&oacute;b.&nbsp;</p>',
      },
    ]);
  }

  public readObjectivesList( options: ListFetchOptions, url: RestObject): Observable<Objective | any> {
    return of({
      count: 1,
      max_page_size: 200,
      next: null,
      previous: null,
      results: this.data as Array<Objective>,
    });
  }
}
