import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  EmailAccountSettingsData,
  EmailAccountSettings,
} from '../data/email-account-settings';

@Injectable()
export class EmailAccountSettingsService extends EmailAccountSettingsData {
  constructor(public http: HttpClient) {
    super('email-account', http);
  }
}
