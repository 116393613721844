import { Injectable } from '@angular/core';
import { HistoryData } from '../data/history';

@Injectable()
export class HistoryMockService extends HistoryData {
  constructor() {
    super('HISTORY', [
      {
        uid: '11111111',
        message: 'Jakas wiadomość',
        details: 'jakieś detale',
        level: 'H',
        timestamp: '2019-07-17T05:22:09.494416Z',
      },
      {
        uid: '22222222',
        message: 'Jakas wiadomość',
        details: 'jakieś detale',
        level: 'L',
        timestamp: '2019-07-17T05:22:09.494416Z',
      },
      {
        uid: '33333333333333333',
        message: 'Jakas wiadomość',
        details: 'jakieś detale',
        level: 'I',
        timestamp: '2019-07-17T05:22:09.494416Z',
      },
      {
        uid: '55555555555555555555',
        message: 'Jakas wiadomość',
        details: 'jakieś detale',
        level: 'C',
        timestamp: '2019-07-17T05:22:09.494416Z',
      },
      {
        uid: '6666666666666666666666666',
        message: 'Jakas wiadomość',
        details: 'jakieś detale',
        level: 'C',
        timestamp: '2019-07-17T05:22:09.494416Z',
      },
      {
        uid: '777777777777777777777777777777',
        message: 'Jakas wiadomość',
        details: 'jakieś detale',
        level: 'H',
        timestamp: '2019-07-17T05:22:09.494416Z',
      },
      {
        uid: '888888888888888888888888888',
        message: 'Jakas wiadomość',
        details: 'jakieś detale',
        level: 'H',
        timestamp: '2019-07-17T05:22:09.494416Z',
      },
      {
        uid: '9999999999999999999999',
        message: 'Jakas wiadomość',
        details: 'jakieś detale',
        level: 'H',
        timestamp: '2019-07-17T05:22:09.494416Z',
      },
    ]);
  }
}
