import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  SessionChangePasswordRequest,
  SessionChangePasswordResponse,
  SessionData,
} from '../data/session';
import { Observable, of } from 'rxjs';

@Injectable()
export class SessionService extends SessionData {
  constructor(public http: HttpClient) {
    super('session', http);
  }

  public changePassword(req: SessionChangePasswordRequest): Observable<SessionChangePasswordResponse | any> {
    return this.http.patch<SessionChangePasswordResponse>(this.url + this.endpoint + '/', req);
  }
}
