import { Injectable } from '@angular/core';
import { FilterSettingData, FilterSetting } from '../data/filter-setting';
import { Observable, of } from 'rxjs';

@Injectable()
export class FilterSettingMockService extends FilterSettingData {
  constructor() {
    super('IP-FILTER-SETTING', [
      {
        uid: '2ade8181-599e-4308-bbe0-abf06aa8cff8',
        ip_black_list_filter: true,
        countries_filter: true,
        support_center: false,
      },
    ]);
  }

  public get(): Observable<FilterSetting | any> {
    const d: Array<any> = this.data as Array<any>;
    return of(d);
  }
}
