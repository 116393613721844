import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  SettingsIntervalsData,
} from '../data/settings-intervals';

@Injectable()
export class SettingsIntervalsService extends SettingsIntervalsData {
  constructor(public http: HttpClient) {
    super('mailing-interval', http);
  }
}
