import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { RestObject } from './rest-framework';
import { Persons } from './persons';
import { Scenario } from './scenario';
import { Parameter } from './parameters';

export interface Campaign extends RestObject {
  name: string;
  description: string;
  status: string;
  awareness_enabled: boolean;
  awareness_mailing_interval_uid: string;
  awareness_email_account_uid: string;
  date_add: string;
}

export interface CampaignChart extends RestObject {
  name: string;
  description: string;
  status: string;
  awareness_enabled: boolean;
  awareness_mailing_interval_uid: string;
  awareness_email_account_uid: string;
  date_add: string;
  progress: Progress[];
}

export interface Progress {
  ok: number;
  error: number;
  sent: number;
  edu_error: number;
  edu_not_sent: number;
  edu_ok: number;
  edu_sent: number;
  edu_sent_error: number;
  edu_sent_ok: number;
  education: number;
  not_sent: number;
  sent_error: number;
  sent_ok: number;
  chart: Chart[];
}

export interface Chart {
  name: string;
  value: number;
}

export interface CampaignResponse {
  status: string;
  info: string;
}

export interface NewCampaignRequest {
  uid: string;
  name: string;
}

export interface Statistics extends RestObject {
  campaign: CampaignStatus;
  campaign_scenarions: CampaignScenarios[];
}

export interface CampaignStatus extends RestObject {
  active_time: string;
  all_recipients: number;
  not_sent: number;
  scenarios: number;
  sent_error: number;
  sent_ok: number;
  status: string;
}

export interface CampaignScenarios extends RestObject {
  name: string;
  description: string;
  all_recipients: number;
  sent_ok: number;
  sent_error: number;
  not_sent: number;
  objectives: Objective[];
  recipients?: RecipientWithObjectives[];
}

export interface CampaignScenario extends RestObject {
  campaign_uid: string;
  date_start: string;
  email_account_uid: number;
  mail_body_female: string;
  mail_body_male: string;
  mail_subject: string;
  mailing_interval_uid: string;
  progress: string;
  randomize_recipients: boolean;
  scenario_uid: string;
  scenariotype: string;
  scenario: Scenario[];
  parameters?: Parameter[];
}

export interface Objective extends RestObject {
  severity: string;
  description: string;
  count: number;
  rate: string;
}

export interface CampaignProgressResponse {
  sent_ok: number;
  sent_error: number;
  not_sent: number;
  edu_sent_ok: number;
  edu_sent_error: number;
  edu_not_sent: number;
  education: boolean;
}

export interface RecipientWithObjectives {
  uid: string;
  campaign_scenario_uid: string;
  person_uid: Persons[];
  hashtags: [string];
  mail_sent: boolean;
  edu_sent: boolean;
  status: string;
  objectives: Objective[];
}


export abstract class CampaignsData extends RestFrameworkService<Campaign> {
  public abstract campaignClone(_: NewCampaignRequest): Observable<CampaignResponse | any>;
  public abstract campaignSendEduEmails(uid: RestObject): Observable<any>;
  public abstract loadCampaignStatistics(uid: RestObject): Observable<Statistics| any>;
  public abstract loadCampaignScenario(uid: RestObject): Observable<CampaignScenario | any>;
  public abstract loadCampaignProgress(): Observable<CampaignProgressResponse |any>;
  public abstract campaignResendEmails(uid: RestObject): Observable<CampaignResponse |any>;
  public abstract campaignResendEduEmails(uid: RestObject): Observable<CampaignResponse |any>;
  public abstract loadRecipientsWithObjectives(uid: string): Observable<RecipientWithObjectives |any>;
  public abstract loadAllRecipients(uid: string): Observable< any>;
}
