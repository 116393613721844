import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  CampaignsData,
  NewCampaignRequest,
  CampaignResponse,
  Statistics,
  CampaignScenario,
} from '../data/campaigns';
import { of } from 'rxjs';
import { RestObject } from '../data/rest-framework';

@Injectable()
export class CampaignsMockService extends CampaignsData {
  recipients = {
    count: 1,
    max_page_size: 200,
    next: null,
    previous: null,
    results: [
      {
        uid: '0ca0cde9-d776-488c-a033-56c6a80417db',
        campaign_scenario_uid: '2804c4f6-d396-4ed6-bec6-aeaca72df3e6',
        person_uid: {
          uid: '1b80be5d-91a0-46f7-b7a4-65e1002c00fd',
          firstname: 'dsad',
          lastname: 'dsada',
          isMale: false,
          position: 'dsad',
          email: 'dawid.cieslak@mysza.software',
          phone: '32',
          comment: null,
        },
        hashtags: [],
        mail_sent: true,
        edu_sent: false,
        status: 'K',
        objectives: [],
      },
    ],
  };
  constructor() {
    super('CAMPAIGNS', [
      {
        uid: '711ed741-f048-4636-a735-e27f47b0ac0d',
        name: 'TEst',
        description: 'dsadsa',
        status: 'C',
        awareness_enabled: false,
        awareness_mailing_interval_uid: null,
        awareness_email_account_uid: null,
        date_add: '2021-01-27T11:24:44.471266Z',
      },
      {
        uid: '711ed741-f048-4636-6778-e27f47b0ac11',
        name: 'qq',
        description: 'qqqqqq',
        status: 'I',
        awareness_enabled: true,
        awareness_mailing_interval_uid: null,
        awareness_email_account_uid: null,
        date_add: '2021-01-27T11:24:44.471266Z',
      },
    ]);
  }

  public campaignClone(
    _: NewCampaignRequest,
  ): Observable<CampaignResponse | any> {
    return of({
      data: {
        status: 'ok',
        info: 'jestes glupi',
      },
    });
  }

  public campaignSendEduEmails(
    _: RestObject,
  ): Observable<CampaignResponse | any> {
    return of({
      data: {
        status: 'ok',
        info: 'Education started.',
      },
    });
  }

  public loadCampaignStatistics(_: RestObject): Observable<Statistics | any> {
    return of({
      campaign: {
        scenarios: 1,
        status: 'C',
        all_recipients: 0,
        sent_ok: 0,
        sent_error: 0,
        not_sent: 0,
        active_time: 'unknown',
      },
      campaign_scenarions: [
        {
          uid: '2b6f2c28-7fa1-42c2-b9d5-f0bedc79346c',
          name:
            'Oferta pracy - rekruteka.pl treść edukacyjna natychmiast  ( bez infekcji ).',
          description:
            'Fałszywa oferta pracy, której szczegóły rzekomo dostępne są w pliku do pobrania. Po otworzeniu linku należy wpisać proste hasło zabezpieczające spersonalizowaną ofertę (jest w treści maila). Po wpisaniu hasła użytkownik natychmiast widzi materiały edukacyjne.',
          all_recipients: 0,
          sent_ok: 0,
          sent_error: 0,
          not_sent: 0,
          objectives: [
            {
              uid: 'f47e7eec-70bb-491a-9cf3-eef6faccffab',
              severity: 'L',
              description: 'Kliknięcie w link.',
              count: 0,
              rate: '0%',
            },
            {
              uid: '8114f141-672a-4365-abe0-5a941b41f0a6',
              severity: 'M',
              description: 'Pobieranie dokument&oacute;w.',
              count: 0,
              rate: '0%',
            },
          ],
        },
      ],
    });
  }

  public loadCampaignScenario(uid): Observable<CampaignScenario | any> {
    return of({
      data: {
        status: 'ok',
        info: 'progrss',
      },
    });
  }


  public loadCampaignProgress(): Observable<CampaignResponse | any> {
    return of({
      data: {
        status: 'ok',
        info: 'progrss',
      },
    });
  }

  public campaignResendEmails(): Observable<CampaignResponse | any> {
    return of({
      data: {
        status: 'ok',
        info: 'Retry 1 recipients.',
      },
    });
  }

  public campaignResendEduEmails(): Observable<CampaignResponse | any> {
    return of({
      data: {
        status: 'ok',
        info: 'resend.',
      },
    });
  }

  public loadRecipientsWithObjectives(): Observable<CampaignResponse | any> {
    return observableOf(this.recipients);
  }

  public loadAllRecipients(): Observable< any> {
    return observableOf(this.recipients);
  }
}
