import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FileData } from '../data/file';

@Injectable()
export class FileUploadMockService extends FileData {

    public upload(_: File): Observable<any> {
        return of(this.data);
    }

    constructor() {
        super('file', [
            {
                summary: {
                    parsed_lines: 5,
                    updated_persons: 1,
                    new_persons: 2,
                    new_groups: 3,
                    persons_to_group_added: 5,
                    errors: 3,
                    Domain_not_allowed: 1,
                },
                errors: [
                    {
                        line: '1',
                        line_body: 'Line Error',
                        error_message: 'Error mesage',
                    },
                    {
                        line: '2',
                        line_body: 'Line Error2',
                        error_message: 'Error mesage2',
                    },
                    {
                        line: '3',
                        line_body: 'Line Error3',
                        error_message: 'Error mesage3',
                    },
                ],
            },
        ]);
    }

}
