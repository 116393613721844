import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  CheckEdumailRequest,
  CheckEdumailResponse,
  CheckEdumailData,
} from '../data/check-edumail';
import { Observable } from 'rxjs';

@Injectable()
export class CheckEdumailService extends CheckEdumailData {
  constructor(public http: HttpClient) {
    super('check-edumail', http);
  }

  public test(
    req: CheckEdumailRequest,
  ): Observable<CheckEdumailResponse | any> {
    return this.http.post<CheckEdumailResponse>(
      this.url + this.endpoint + '/',
      req,
    );
  }
}
