import { Injectable } from '@angular/core';
import {
  Persons,
  PersonsData,
  PersonsUploadResponse,
} from '../data/persons';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListFetchOptions, RestFrameworkListResponse } from '../data/rest-framework';

@Injectable()
export class PersonService extends PersonsData {

  constructor(public http: HttpClient) {
    super('person', http);
  }

  public educate(res: string): Observable<any> {
    return this.http.get<PersonsData>(this.url + this.endpoint + '/' + res + '/educate/');
  }

  public educateMany(uids: any): Observable<any> {
    return (this.data as HttpClient).request<any>('post',
      this.url + this.endpoint + '/educate_many/',
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: {
          'uuids': uids,
        },
      },
    );
  }

  public upload(req: File): Observable<PersonsUploadResponse | any> {
    return this.genericUploadContentMultipart<PersonsUploadResponse>(req,
      this.url + this.endpoint + '/upload/');
  }
}
