import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { ListFetchOptions, RestObject } from './rest-framework';

export interface GeneralStatistics {
  counts: Counts;
  coverage: Coverage;
  objectives: Severities;
  scenarios_most_effective5: EffectiveSceanrio;
  scenarios_usage: UsedScenario[];
}

export interface Counts {
  campaings: number;
  events: number;
  mails: number;
  scenarios: number;
  tricked_employees: number;
}

export interface Severities {
  critical: number;
  high: number;
  medium: number;
  low: number;
}

export interface Coverage {
  all: number;
  phished: number;
}

export interface EffectiveSceanrio {
  name: string;
  count: number;
}

export interface UsedScenario {
  name: string;
  count: number;
}

export abstract class StatisticsGeneralData extends RestFrameworkService<GeneralStatistics> {
}
