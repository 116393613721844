import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { RestObject } from './rest-framework';

export interface Intervals extends RestObject {
  emails_per_pack: number;
  emails_delay: number;
  pack_delay: number;
}

export abstract class SettingsIntervalsData extends RestFrameworkService<Intervals> {}
