import { Injectable } from '@angular/core';
import { HistoryData, History } from '../data/history';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class HistoryService extends HistoryData {
  constructor(public http: HttpClient) {
    super('history', http);
  }
}
