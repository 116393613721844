<form [formGroup]="changePassForm" (submit)="changePassword()">
  <nb-card>
    <nb-card-header>{{ 'change_password.title' | translate }}</nb-card-header>
    <nb-card-body>
      <div class="form-group row">
        <label for="oldpass"
               class="label col-sm-3 col-form-label">{{ 'change_password.old_password' | translate }}</label>
        <div class="col-sm-9">
          <input
            id="oldpass"
            formControlName="oldpass"
            nbInput
            type="password"
            placeholder="{{ 'change_password.old_password' | translate }}"
          >
          <ng-container
            *ngIf="changePassForm.get('oldpass').errors && (changePassForm.get('oldpass').dirty || changePassForm.get('oldpass').touched)">
            <p class="caption status-danger"
               *ngIf="changePassForm.get('oldpass').hasError('required')">
              {{ 'change_password.old_password_required' | translate }}
            </p>
          </ng-container>
        </div>
      </div>
      <div class="form-group row">
        <label for="newpass"
               class="label col-sm-3 col-form-label">{{ 'change_password.new_password' | translate }}</label>
        <div class="col-sm-9">
          <input nbInput
                 id="newpass"
                 formControlName="newpass"
                 placeholder="{{ 'change_password.new_password' | translate }}"
                 type="password"
          >
          <ng-container
            *ngIf="changePassForm.get('newpass').dirty || changePassForm.get('newpass').touched">
            <p class="caption status-danger"
               *ngIf="changePassForm.get('newpass').hasError('required')">
              {{ 'change_password.new_password_required' | translate }}
            </p>
            <p class="caption status-danger"
               *ngIf="changePassForm.get('newpass').hasError('pattern')">
              {{  'change_password.new_password_pattern' | translate }}
            </p>
            <p class="caption status-danger"
               *ngIf="changePassForm.hasError('checkSameAsOld')">
              {{  'change_password.new_password_same_ad_old' | translate }}
            </p>
          </ng-container>
        </div>
      </div>
      <div class="form-group row">
        <label for="newpass2"
               class="label col-sm-3 col-form-label">{{ 'change_password.repeat_password' | translate }}</label>
        <div class="col-sm-9">
          <input nbInput
                 id="newpass2"
                 formControlName="newpass2"
                 type="password"
                 placeholder="{{ 'change_password.repeat_password' | translate }}">
          <ng-container
            *ngIf="changePassForm.get('newpass2').dirty || changePassForm.get('newpass2').touched">
            <p class="caption status-danger"
               *ngIf="changePassForm.hasError('checkPasswordRepeated')">
              {{ 'change_password.repeated_password_not_same' | translate }}
            </p>
            <p class="caption status-danger"
               *ngIf="changePassForm.get('newpass2').hasError('required')">
              {{ 'change_password.repeat_password_required' | translate }}
            </p>
          </ng-container>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button class="cancel" nbButton status="danger" (click)="cancel()"
              [disabled]="pending">{{ 'change_password.cancel' | translate }}
      </button>
      <button nbButton status="success" type="submit"
              [disabled]="pending || changePassForm.errors || changePassForm.pristine">{{ 'change_password.change' | translate }}</button>
    </nb-card-footer>
  </nb-card>
</form>
