import { Component } from '@angular/core';
import { NbComponentStatus, NbDialogService } from '@nebular/theme';
import { CookieTempService } from '../../../@core/services/cookie.service';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  // template: `
  //   <span class="created-by">
  //     Created with ♥ by
  //     <b><a href="https://akveo.page.link/8V2f" target="_blank">Akveo</a></b>
  //     2019
  //   </span>
  //   <div class="socials">
  //     <a href="#" target="_blank" class="ion ion-social-github"></a>
  //     <a href="#" target="_blank" class="ion ion-social-facebook"></a>
  //     <a href="#" target="_blank" class="ion ion-social-twitter"></a>
  //     <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
  //   </div>
  // `,
  templateUrl: './footer.component.html',
})

export class FooterComponent {

  buttonStatuse: NbComponentStatus = 'success';
  language: string = null;
  expire;

  constructor(public cookieService: CookieTempService) {

  }

  acceptCookie() {
    this.cookieService.acceptCookie();
  }
}
