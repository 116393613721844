import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';

export interface LicenseResponse {
  ValiFor: string;
  IsActive: boolean;
  ValidUntil: string;
}

export abstract class LicenseData extends RestFrameworkService<{}> {
  public abstract getLicense(): Observable<LicenseResponse | any>;
}
