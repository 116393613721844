import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { ListFetchOptions, RestFrameworkListResponse, RestObject } from './rest-framework';

export interface Persons extends RestObject {
  uid: string;
  firstname: string;
  lastname: string;
  isMale: boolean;
  position: string;
  email: string;
  phone: number;

  comment?: string;
  extra?: number;
  username?: string;
  computername?: string;
}

export interface PersonsUploadRequest {
  file: File;

}

export interface PersonsUploadResponse {
  result: any;
}

export abstract class PersonsData extends RestFrameworkService<Persons> {

  public abstract upload(_: File): Observable<PersonsUploadResponse | any>;

  public abstract educate(_: string): Observable<any>;

  public abstract educateMany(_: string[]): Observable<any>;
}

