export enum LogLevel {
  DEBUG = 0,
  INFO = 1,
  TRACE = 2,
  LOG = 4,
  WARN = 5,
  ERROR = 6,
  SUCCES = 7,
}

export interface Message {
  level: LogLevel;
  message: string | any;
  data?: any;
}
