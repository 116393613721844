import { Injectable } from '@angular/core';
import {TagData,
} from '../data/tag';
import { Observable, of } from 'rxjs';

@Injectable()
export class TagMockService extends TagData {

  constructor() {
    super('Tag', [{
      '%PERSON.EMAIL%': 'Receiver email',
      '%PERSON.FIRSTNAME%': 'Receiver firstname.',
      '%PERSON.LASTNAME%': 'Receiver lastname.',
      '%PERSON.PHONE%': 'Receiver phone.',
      '%PERSON.POSITION%': 'Receiver position.',
      '%RECIPIENT.CODE%': 'Unique per campaign persons code.',
      '%SCENARIO.LINK%': 'Link of scenario for lured recipient (if exists).',
      '%SCENARIO.SITE%': 'Site address of phishing scenario (if exists).',
    }]);
  }
}
