import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';
import { RestObject } from './rest-framework';
import { Persons } from './persons';
import { Parameter } from './parameters';

export interface Scenario extends RestObject {
  name: string;
  description: string;
  scenariotype: string;
  url: string;
  mail_subject: string;
  mail_body_male: string;
  mail_body_female: string;
  awareness_content: string;
  awareness_mail_subject: string;
  severity: string;
  language: string;
  parameters?: Parameter[];
}


export abstract class DashboardData extends RestFrameworkService<any> {

}
