import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { EventsData, Event } from '../data/events';

@Injectable()
export class EventsMockService extends EventsData {
  constructor() {
    super('EVENTS', [
      {
        uid: '711ed741-f048-4636-a735-e27f47b0ac0d',
        timestamp: '2021-01-14T10:26:22Z',
        recipient_code: '42caa3b631',
        objective_code: 'visitnoinf',
        ip: '195.28.170.199',
        useragent:
          'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.135 Safari/537.36',
        details: 'ssssssssssssssssss sssssssssssssssssssss',
        severity: 'C',
        campaign_scenario_uid: '00308e2c-25f2-478d-be9a-a39bca903b15',
        objective_description: 'dupa.',
        objective_uid: 'f47e7eec-70bb-491a-9cf3-eef6faccffab',
        recipient_uid: null,
        recipient_name: 'Dawid Cieślak',
        recipient_email: '',
        scenario_name:
          'Oferta pracy - rekruteka.pl treść edukacyjna natychmiast  ( bez infekcji ).',
        scenario_description:
          'Fałszywa oferta pracy, której szczegóły rzekomo dostępne są w pliku do pobrania. Po otworzeniu linku należy wpisać proste hasło zabezpieczające spersonalizowaną ofertę (jest w treści maila). Po wpisaniu hasła użytkownik natychmiast widzi materiały edukacyjne.',
        campaign_name: 'Testowa1',
        campaign_description: 'Testowania apta',
        country: 'pl',
        filtered: false,
      },
      {
        uid: '711ed741-f048-4636-a735-e27f47b0acdd',
        timestamp: '2021-01-14T10:26:22Z',
        recipient_code: '42caa3b631',
        objective_code: 'visitnoinf',
        ip: '195.28.170.199',
        useragent:
          'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.135 Safari/537.36',
        details: 'ssssssssssssssssss sssssssssssssssssssss',
        severity: 'L',
        campaign_scenario_uid: '00308e2c-25f2-478d-be9a-a39bca903b15',
        objective_description: 'Kliknięcie w link.',
        objective_uid: 'f47e7eec-70bb-491a-9cf3-eef6faccffab',
        recipient_uid: null,
        recipient_name: 'Dawid',
        recipient_email: '',
        scenario_name:
          'Oferta pracy - rekruteka.pl treść edukacyjna natychmiast  ( bez infekcji ).',
        scenario_description:
          'Fałszywa oferta pracy, której szczegóły rzekomo dostępne są w pliku do pobrania. Po otworzeniu linku należy wpisać proste hasło zabezpieczające spersonalizowaną ofertę (jest w treści maila). Po wpisaniu hasła użytkownik natychmiast widzi materiały edukacyjne.',
        campaign_name: 'Testowa1',
        campaign_description: 'Testowania apta',
        country: 'pl',
        filtered: false,
      },
      {
        uid: '711ed741-f048-4636-a735-e27f47b0ackk',
        timestamp: '2021-01-14T10:26:22Z',
        recipient_code: '42caa3b631',
        objective_code: 'visitnoinf',
        ip: '195.28.170.199',
        useragent:
          'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.135 Safari/537.36',
        details: 'ssssssssssssssssss sssssssssssssssssssss',
        severity: 'H',
        campaign_scenario_uid: '00308e2c-25f2-478d-be9a-a39bca903b15',
        objective_description: 'Kliknięcie w link.',
        objective_uid: 'f47e7eec-70bb-491a-9cf3-eef6faccffab',
        recipient_uid: null,
        recipient_name: 'Karol',
        recipient_email: '',
        scenario_name:
          'Oferta pracy - rekruteka.pl treść edukacyjna natychmiast  ( bez infekcji ).',
        scenario_description:
          'Fałszywa oferta pracy, której szczegóły rzekomo dostępne są w pliku do pobrania. Po otworzeniu linku należy wpisać proste hasło zabezpieczające spersonalizowaną ofertę (jest w treści maila). Po wpisaniu hasła użytkownik natychmiast widzi materiały edukacyjne.',
        campaign_name: 'Testowa1',
        campaign_description: 'Testowania apta',
        country: 'pl',
        filtered: false,
      },
      {
        uid: '711ed741-f048-4636-a735-e27f47b0ac0b',
        timestamp: '2021-01-14T10:26:22Z',
        recipient_code: '42caa3b631',
        objective_code: 'visitnoinf',
        ip: '195.28.170.199',
        useragent:
          'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.135 Safari/537.36',
        details: 'ssssssssssssssssss sssssssssssssssssssss',
        severity: 'I',
        campaign_scenario_uid: '00308e2c-25f2-478d-be9a-a39bca903b15',
        objective_description: 'Kliknięcie w link.',
        objective_uid: 'f47e7eec-70bb-491a-9cf3-eef6faccffab',
        recipient_uid: null,
        recipient_name: 'Bartosz',
        recipient_email: '',
        scenario_name:
          'Oferta pracy - rekruteka.pl treść edukacyjna natychmiast  ( bez infekcji ).',
        scenario_description:
          'Fałszywa oferta pracy, której szczegóły rzekomo dostępne są w pliku do pobrania. Po otworzeniu linku należy wpisać proste hasło zabezpieczające spersonalizowaną ofertę (jest w treści maila). Po wpisaniu hasła użytkownik natychmiast widzi materiały edukacyjne.',
        campaign_name: 'Testowa1',
        campaign_description: 'Testowania apta',
        country: 'pl',
        filtered: false,
      },
      {
        uid: '711ed741-f048-4636-a735-e27f47b0ac0k',
        timestamp: '2021-01-14T10:26:22Z',
        recipient_code: '42caa3b631',
        objective_code: 'visitnoinf',
        ip: '195.28.170.199',
        useragent:
          'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.135 Safari/537.36',
        details: 'ssssssssssssssssss sssssssssssssssssssss',
        severity: 'R',
        campaign_scenario_uid: '00308e2c-25f2-478d-be9a-a39bca903b15',
        objective_description: 'Kliknięcie w link.',
        objective_uid: 'f47e7eec-70bb-491a-9cf3-eef6faccffab',
        recipient_uid: null,
        recipient_name: 'Karolina',
        recipient_email: '',
        scenario_name:
          'Oferta pracy - rekruteka.pl treść edukacyjna natychmiast  ( bez infekcji ).',
        scenario_description:
          'Fałszywa oferta pracy, której szczegóły rzekomo dostępne są w pliku do pobrania. Po otworzeniu linku należy wpisać proste hasło zabezpieczające spersonalizowaną ofertę (jest w treści maila). Po wpisaniu hasła użytkownik natychmiast widzi materiały edukacyjne.',
        campaign_name: 'Testowa1',
        campaign_description: 'Testowania apta',
        country: 'pl',
        filtered: false,
      },
      {
        uid: '711ed741-f048-4636-a735-e27f47b0ac0e',
        timestamp: '2021-01-14T10:26:22Z',
        recipient_code: '42caa3b631',
        objective_code: 'visitnoinf',
        ip: '195.28.170.199',
        useragent:
          'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.135 Safari/537.36',
        details: 'ssssssssssssssssss sssssssssssssssssssss',
        severity: 'R',
        campaign_scenario_uid: '00308e2c-25f2-478d-be9a-a39bca903b15',
        objective_description: 'Kliknięcie w link.',
        objective_uid: 'f47e7eec-70bb-491a-9cf3-eef6faccffab',
        recipient_uid: null,
        recipient_name: 'Ewelina',
        recipient_email: '',
        scenario_name:
          'Oferta pracy - rekruteka.pl treść edukacyjna natychmiast  ( bez infekcji ).',
        scenario_description:
          'Fałszywa oferta pracy, której szczegóły rzekomo dostępne są w pliku do pobrania. Po otworzeniu linku należy wpisać proste hasło zabezpieczające spersonalizowaną ofertę (jest w treści maila). Po wpisaniu hasła użytkownik natychmiast widzi materiały edukacyjne.',
        campaign_name: 'Testowa1',
        campaign_description: 'Testowania apta',
        country: 'pl',
        filtered: false,
      },
    ]);
  }
}
