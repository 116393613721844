import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { TagData } from '../data/tag';
import { RestFrameworkService } from './rest-framework-service';

@Injectable()
export class TagService extends TagData {
  constructor(public http: HttpClient) {
    super('tags', http);
  }
}
