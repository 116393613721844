import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SupportRequest, SupportResponse } from '../data/support';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SupportService {
  private apiendpoint = '/frontend';
  private name = 'ticket';

  constructor(private http: HttpClient) {}

  addTicket(obj: SupportRequest): Promise<SupportResponse> {
    return new Promise<SupportResponse>((resolve, reject) => {
      this.http
        .post<SupportResponse>(this.apiendpoint + '/' + this.name + '/', obj)
        .subscribe(
          (data) => {
            return resolve(data);
          },
          (error) => {
            return reject(error);
          },
        );
    });
  }
}
