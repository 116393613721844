import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'campaignStatus' })
export class CampaignStatusPipe implements PipeTransform {
  transform(status: string): string {
      const statuses = {
          'C': 'Configuring',
          'R': 'Ready',
          'A': 'Active',
          'I': 'Done',
          'P': 'Paused',
          'X': 'Cancelled',
          'E': 'Sending edu emails',
          'D': 'Education done',
    };
    return statuses[status] || 'Unknown';
  }
}
