import { Injectable } from '@angular/core';
import { ScenarioData, Scenario } from '../data/scenario';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ListFetchOptions, RestFrameworkListResponse, RestObject } from '../data/rest-framework';

@Injectable()
export class DashboardService extends ScenarioData {

  constructor(public http: HttpClient) {
    super('dashboard', http);
  }
}
