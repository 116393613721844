import { Observable } from 'rxjs';
import { RestFrameworkService } from '../services/rest-framework-service';

export interface CheckEdumailRequest {
  testEmail: string;
  testAccount: string;
}

export interface CheckEdumailResponse {
  status: string;
  info?: object;
}

export abstract class CheckEdumailData extends RestFrameworkService<{}> {
  public abstract test(
    _: CheckEdumailRequest,
  ): Observable<CheckEdumailResponse | any>;
}
