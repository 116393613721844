import { Injectable } from '@angular/core';
import { CountryFilteringSettingsData } from '../data/country-filtering-settings';

@Injectable()
export class CountryFilteringSettingsMockService extends CountryFilteringSettingsData {
  constructor() {
    super('COUNTRY', [
      {
        uid: '159c32ca-1f5c-4527-a77f-2ee9390e5119',
        prefix: 'ad',
        status: true,
      },
      {
        uid: 'b87b8e37-5b49-4512-8298-582a412a019d',
        prefix: 'ae',
        status: true,
      },
      {
        uid: '04044fc3-b0e0-4b14-b798-54b91de5137d',
        prefix: 'af',
        status: false,
      },
      {
        uid: '5fd8bf22-1011-470d-8c99-ac45a8e290b5',
        prefix: 'ag',
        status: false,
      },
      {
        uid: '97b39631-697e-4096-a44a-e9274fd5c376',
        prefix: 'ai',
        status: false,
      },
      {
        uid: '9289cad9-329d-453a-9087-b09c8b65ef55',
        prefix: 'al',
        status: false,
      },
      {
        uid: '9b8e3487-9d81-4940-945d-5aa9e50dcb81',
        prefix: 'am',
        status: false,
      },
      {
        uid: 'c947df88-765b-4826-889d-e290ba0a7ec4',
        prefix: 'ao',
        status: false,
      },
      {
        uid: '3f3420c0-1223-47b6-a83a-a4f5f961c158',
        prefix: 'aq',
        status: false,
      },
      {
        uid: 'f1aea545-37b5-4cd2-82e9-f74e7c4c9ce0',
        prefix: 'ar',
        status: false,
      },
      {
        uid: 'dbba2c8b-cacc-4c1b-858b-2e70e56ec613',
        prefix: 'as',
        status: false,
      },
      {
        uid: '2b464a04-27ec-495e-ba10-de7199bef1bb',
        prefix: 'at',
        status: false,
      },
      {
        uid: '06e3a06d-ae9d-42db-8848-eac97268d3d4',
        prefix: 'au',
        status: false,
      },
      {
        uid: '6acc4713-22aa-4e44-9b88-64ca0068cbd4',
        prefix: 'aw',
        status: false,
      },
      {
        uid: '1a15e342-7bf9-49d0-925a-40785f71b9f6',
        prefix: 'ax',
        status: false,
      },
      {
        uid: '044acbbe-bbc0-4787-bc54-fc4838743177',
        prefix: 'az',
        status: false,
      },
      {
        uid: '0b022fad-0226-49f8-9d16-9a843774c9e4',
        prefix: 'ba',
        status: false,
      },
      {
        uid: '5ac1d46a-0dc2-487e-8c5c-3fc556135e68',
        prefix: 'bb',
        status: false,
      },
      {
        uid: '56eff1c2-663f-4c72-9be5-c23e61b1024d',
        prefix: 'bd',
        status: false,
      },
      {
        uid: 'ef2e6bc9-6242-40ff-b533-d3b001e53dd7',
        prefix: 'be',
        status: false,
      },
      {
        uid: '5e7cefc6-a480-4388-8c5e-abc8a36597bd',
        prefix: 'bf',
        status: false,
      },
      {
        uid: '91996cd0-9b61-499b-97dd-7f90a50e3cca',
        prefix: 'bg',
        status: false,
      },
      {
        uid: '9edaa44e-b91f-42b9-84d1-4d9278aab65d',
        prefix: 'bh',
        status: false,
      },
      {
        uid: 'd85c81db-39d9-4518-a5ac-e8eb3eff8e88',
        prefix: 'bi',
        status: false,
      },
      {
        uid: 'c15571c8-807b-4040-96f7-d6d9a714cb9b',
        prefix: 'bj',
        status: false,
      },
      {
        uid: '1f463c96-984f-4a9e-a0dd-a8d2d19c41c7',
        prefix: 'bl',
        status: false,
      },
      {
        uid: 'd0f553ed-340c-4f48-8252-a09d6fc9ddb2',
        prefix: 'bm',
        status: false,
      },
      {
        uid: 'd796368f-10d2-4f6a-b48c-d058030b6bb4',
        prefix: 'bn',
        status: false,
      },
      {
        uid: '5b7faa12-7407-4216-a043-818277ea71b5',
        prefix: 'bo',
        status: false,
      },
      {
        uid: '007f9ccb-8a41-480c-9953-b28646ff49b6',
        prefix: 'bq',
        status: false,
      },
      {
        uid: '72c62ad4-e475-4524-9456-095c7b682a2a',
        prefix: 'br',
        status: false,
      },
      {
        uid: '8e54fcd8-dd22-48f1-9404-1f4ca8df422a',
        prefix: 'bs',
        status: false,
      },
      {
        uid: '6f8bac95-e624-4024-892f-743ca068dd6c',
        prefix: 'bt',
        status: false,
      },
      {
        uid: '4e1081e4-d820-4115-b9c8-0f53fed0aa51',
        prefix: 'bv',
        status: false,
      },
      {
        uid: '60d24b31-8ebf-4e26-b8bc-f440780873f6',
        prefix: 'bw',
        status: false,
      },
      {
        uid: '5de2a29b-31b4-4f61-b22c-5fa016dcd923',
        prefix: 'by',
        status: false,
      },
      {
        uid: 'e84c9ca3-b352-47cd-bb21-6e6682f876d0',
        prefix: 'bz',
        status: false,
      },
      {
        uid: '82b179af-af78-41f0-bc15-d429da40665d',
        prefix: 'ca',
        status: false,
      },
      {
        uid: 'f949ff2f-a8b2-4ff7-beda-9a7d030d33c6',
        prefix: 'cc',
        status: false,
      },
      {
        uid: '9b1afcb1-22c8-4fec-8ac2-a4631eb2cc7b',
        prefix: 'cd',
        status: false,
      },
      {
        uid: '85f226e9-0095-40be-8d9c-d71222a86210',
        prefix: 'cf',
        status: false,
      },
      {
        uid: 'ea051e47-215a-4de8-aaa8-09ab7dd1cd44',
        prefix: 'cg',
        status: false,
      },
      {
        uid: '038931cd-c10f-44ba-be6b-b8c7dbdc0a8d',
        prefix: 'ch',
        status: false,
      },
      {
        uid: '7d55ac8f-5c6c-4598-b2ff-00acf493c86a',
        prefix: 'ci',
        status: false,
      },
      {
        uid: '20900157-ce53-438f-b507-9e4423ef1a05',
        prefix: 'ck',
        status: false,
      },
      {
        uid: '170f87bf-ea64-4a6e-9e03-c341d4d72a29',
        prefix: 'cl',
        status: false,
      },
      {
        uid: '938514a5-9c2e-4165-8197-a8ce29715dba',
        prefix: 'cm',
        status: false,
      },
      {
        uid: 'b14e09a8-4943-46e3-b58d-72c0ddea80cd',
        prefix: 'cn',
        status: false,
      },
      {
        uid: 'eef746b5-449d-4fde-8777-541c26fe3da0',
        prefix: 'co',
        status: false,
      },
      {
        uid: '15aa637c-68b3-4e25-b29e-d64dcc7b9fab',
        prefix: 'cr',
        status: false,
      },
      {
        uid: '18d6cdc5-c597-4a8d-9782-00d64b55eeab',
        prefix: 'cu',
        status: false,
      },
      {
        uid: 'c19eb7a1-9bd3-4d40-9d0f-c92b0fb2b00a',
        prefix: 'cv',
        status: false,
      },
      {
        uid: 'd3fb30b1-0808-4c68-b474-adeec8e0cb63',
        prefix: 'cw',
        status: false,
      },
      {
        uid: 'c2e59248-967a-4f50-ab4c-8806cc4c6783',
        prefix: 'cx',
        status: false,
      },
      {
        uid: '4a57695a-8a37-48ae-b6a7-69e1100e1f9c',
        prefix: 'cy',
        status: false,
      },
      {
        uid: '6ae7a150-ff70-4de7-97cd-52d476068115',
        prefix: 'cz',
        status: false,
      },
      {
        uid: '8b7745ec-6013-42ef-a6bc-0b3fae06a7e3',
        prefix: 'de',
        status: false,
      },
      {
        uid: 'd49193be-3b38-48ce-bf5b-bccf357658d9',
        prefix: 'dj',
        status: false,
      },
      {
        uid: '6a289a1c-d4fb-433b-bb67-88f412ed71b8',
        prefix: 'dk',
        status: false,
      },
      {
        uid: '12ecbcc5-fdd4-4709-8bac-aadf987d1412',
        prefix: 'dm',
        status: false,
      },
      {
        uid: 'cc64e516-5177-4592-a39b-700bd3093092',
        prefix: 'do',
        status: false,
      },
      {
        uid: '36ee66cb-4f0b-446a-92e2-27c37c494ce6',
        prefix: 'dz',
        status: false,
      },
      {
        uid: 'aeb285e9-70b4-4860-9b67-577e619309fb',
        prefix: 'ec',
        status: false,
      },
      {
        uid: '7a25a9dd-cbf5-4962-8fbb-b1be67dffacc',
        prefix: 'ee',
        status: false,
      },
      {
        uid: '1332326f-dda8-4762-9532-6ff47ce4894c',
        prefix: 'eg',
        status: false,
      },
      {
        uid: 'd0036e6b-5422-48b3-a6c6-e2e78a31895e',
        prefix: 'eh',
        status: false,
      },
      {
        uid: '997ea951-2710-4bbc-b67e-b380d8fb3281',
        prefix: 'er',
        status: false,
      },
      {
        uid: '8b72ca44-5f23-48f7-8683-e82dac3d2c4d',
        prefix: 'es',
        status: false,
      },
      {
        uid: 'a6e1acf2-afe0-4a91-9af4-0df6580cb903',
        prefix: 'et',
        status: false,
      },
      {
        uid: '9ff729e1-82cb-4361-a2d0-e272ad0728d8',
        prefix: 'fi',
        status: false,
      },
      {
        uid: 'b130fc44-0e07-4069-93bf-26826ef7bbd3',
        prefix: 'fj',
        status: false,
      },
      {
        uid: 'a257a7db-16c6-4a7d-890a-00e44f98f43f',
        prefix: 'fk',
        status: false,
      },
      {
        uid: '05ea6509-14d9-4fd9-823a-b785762408c8',
        prefix: 'fm',
        status: false,
      },
      {
        uid: '281de457-f435-4109-8541-7a88d5b1ef4a',
        prefix: 'fo',
        status: false,
      },
      {
        uid: 'af54f7c7-bd45-4b94-9ade-8f88a9f46a76',
        prefix: 'fr',
        status: false,
      },
      {
        uid: '9d7e4568-f08e-4d26-9918-3a4baee9df53',
        prefix: 'ga',
        status: false,
      },
      {
        uid: '6a740e1e-5080-4d31-9fe3-76adef669b2a',
        prefix: 'gb',
        status: false,
      },
      {
        uid: '42f9701a-5067-4c3d-9ed1-6e44044c3180',
        prefix: 'gb-eng',
        status: false,
      },
      {
        uid: '7a058cdf-aed1-4ad1-a037-3ef1ccc2fe99',
        prefix: 'gb-nir',
        status: false,
      },
      {
        uid: '08a5fc19-567e-4f79-8bae-217433010c5f',
        prefix: 'gb-sct',
        status: false,
      },
      {
        uid: 'c71df38d-42e2-401f-a4a2-79949eecce05',
        prefix: 'gb-wls',
        status: false,
      },
      {
        uid: '6f8da714-255f-4a41-9f83-c6175d614c5b',
        prefix: 'gd',
        status: false,
      },
      {
        uid: 'c462101c-63cd-4bde-a6e5-c0d5061a1000',
        prefix: 'ge',
        status: false,
      },
      {
        uid: 'bda710d5-a237-4d0f-900a-cf867c81e4e5',
        prefix: 'gf',
        status: false,
      },
      {
        uid: '3c1ee23d-15a7-4b1a-a934-3dd2203f4329',
        prefix: 'gg',
        status: false,
      },
      {
        uid: '8ae1b2f9-a059-44f7-a749-784b32112927',
        prefix: 'gh',
        status: false,
      },
      {
        uid: '64ba6a93-f66b-4cb4-8681-84b11e68af0c',
        prefix: 'gi',
        status: false,
      },
      {
        uid: 'e03569bf-f9ef-4b82-a7fb-5f1969576a40',
        prefix: 'gl',
        status: false,
      },
      {
        uid: 'ab601e9e-322a-4362-9e95-155141b67ae1',
        prefix: 'gm',
        status: false,
      },
      {
        uid: 'b15276d2-2fab-413f-a4c6-8652a609f23b',
        prefix: 'gn',
        status: false,
      },
      {
        uid: 'bb291de4-2499-43d3-a553-2dba831ae17d',
        prefix: 'gp',
        status: false,
      },
      {
        uid: 'ebbe60f6-263f-410a-8981-07ecc2721e5e',
        prefix: 'gq',
        status: false,
      },
      {
        uid: 'e3c1f1e4-3d47-4ed3-9e10-3607268e1c0d',
        prefix: 'gr',
        status: false,
      },
      {
        uid: 'd6ec8c73-f68e-4e5a-8a55-00556e72d1e6',
        prefix: 'gs',
        status: false,
      },
      {
        uid: 'a48553da-8ab1-43ec-8ad7-0e5e10a69885',
        prefix: 'gt',
        status: false,
      },
      {
        uid: 'b5c4f52d-c002-463c-9e20-c01225ba1e3a',
        prefix: 'gu',
        status: false,
      },
      {
        uid: '34fb62df-2cbe-4213-ad26-d05bbe4883ad',
        prefix: 'gw',
        status: false,
      },
      {
        uid: 'b3759ae6-3c29-462e-9d49-7298c61c2d29',
        prefix: 'gy',
        status: false,
      },
      {
        uid: 'ad10a243-7179-43f8-94a7-e505719f9e87',
        prefix: 'hk',
        status: false,
      },
      {
        uid: '7861db39-7a86-44ac-abfb-491e17f2efb5',
        prefix: 'hm',
        status: false,
      },
      {
        uid: '08dec031-2743-41c7-8199-514dd0c3f00b',
        prefix: 'hn',
        status: false,
      },
      {
        uid: '0869e21e-357b-4c12-904f-161125c4f320',
        prefix: 'hr',
        status: false,
      },
      {
        uid: '569f4b1b-675f-4295-b60e-882aaee38619',
        prefix: 'ht',
        status: false,
      },
      {
        uid: '1935af17-5115-4f2b-84e8-fb395c2e8083',
        prefix: 'hu',
        status: false,
      },
      {
        uid: '1d66fe3b-1778-4836-9955-50b2a6cc3bc4',
        prefix: 'id',
        status: false,
      },
      {
        uid: '9f296f06-6beb-4fe0-8baa-ca8b129ca93b',
        prefix: 'ie',
        status: false,
      },
      {
        uid: '35ef3c9a-3f68-4486-944d-7c2928e3bb11',
        prefix: 'il',
        status: false,
      },
      {
        uid: 'c28738be-4926-4b64-8875-7a2f7644547b',
        prefix: 'im',
        status: false,
      },
      {
        uid: '0a1567d9-a014-4b5e-9ea8-8056dcbcce14',
        prefix: 'in',
        status: false,
      },
      {
        uid: 'fa64d720-e3fb-48be-b745-af3aae217cd7',
        prefix: 'io',
        status: false,
      },
      {
        uid: '2deb7bb7-c789-4545-bc2a-80dcc0c21feb',
        prefix: 'iq',
        status: false,
      },
      {
        uid: '3cf794cb-7c6e-4568-8238-911e0d31da47',
        prefix: 'ir',
        status: false,
      },
      {
        uid: '3ca938c3-cb60-412b-a4cc-9401aa82f368',
        prefix: 'is',
        status: false,
      },
      {
        uid: 'c245fbc1-9806-4184-a854-83a83b79e4b3',
        prefix: 'it',
        status: false,
      },
      {
        uid: '86b3adb1-07d7-45f8-af3b-a9b359fda892',
        prefix: 'je',
        status: false,
      },
      {
        uid: '4f14d514-7d6c-4274-81c5-fb968368e1c8',
        prefix: 'jm',
        status: false,
      },
      {
        uid: '16689466-4500-4cb1-b9e0-6501a310ebb3',
        prefix: 'jo',
        status: false,
      },
      {
        uid: '10962781-45df-457c-bcc8-f09b002d4e18',
        prefix: 'jp',
        status: false,
      },
      {
        uid: 'fd1dabff-53aa-4981-9951-2880209bb5d9',
        prefix: 'ke',
        status: false,
      },
      {
        uid: 'a75c6c38-bbc0-4c55-a2a5-75d20dcb6494',
        prefix: 'kg',
        status: false,
      },
      {
        uid: 'a4956399-6aec-4ac7-bbec-c46140aad8f3',
        prefix: 'kh',
        status: false,
      },
      {
        uid: '999f15fc-5008-4dce-af06-31e9b62baa12',
        prefix: 'ki',
        status: false,
      },
      {
        uid: 'eb56357f-ab10-41c2-ae07-182d7a5d0439',
        prefix: 'km',
        status: false,
      },
      {
        uid: 'e5509869-081b-4b80-9413-be889fab457d',
        prefix: 'kn',
        status: false,
      },
      {
        uid: '38a75760-0dcf-4adb-9c9e-53cfb1c68774',
        prefix: 'kp',
        status: false,
      },
      {
        uid: '2d003aa0-9e99-46c8-8b45-26f5fd40b335',
        prefix: 'kr',
        status: false,
      },
      {
        uid: 'ab6ac8d5-8936-49b5-b3d9-75de870c9a66',
        prefix: 'kw',
        status: false,
      },
      {
        uid: '508ca700-805a-4c18-894f-ddf0c5d9a115',
        prefix: 'ky',
        status: false,
      },
      {
        uid: 'c3c80f84-99df-4070-bc4c-8bd6cfab244a',
        prefix: 'kz',
        status: false,
      },
      {
        uid: '9aec1471-87b7-40f2-9483-ae97d367c0ab',
        prefix: 'la',
        status: false,
      },
      {
        uid: 'e8d177bf-dfc1-4c19-abec-6f276453bbaf',
        prefix: 'lb',
        status: false,
      },
      {
        uid: '92bd082d-50f1-48cb-90fb-7e875fe1cc6e',
        prefix: 'lc',
        status: false,
      },
      {
        uid: 'e802f1b3-7605-4415-b3a5-f9f8f565123f',
        prefix: 'li',
        status: false,
      },
      {
        uid: 'bd7d8d0f-f64d-429b-9e5b-188e549fc9ea',
        prefix: 'lk',
        status: false,
      },
      {
        uid: '0a6c368f-2fca-4fc0-af22-7b3640c9d92f',
        prefix: 'lr',
        status: false,
      },
      {
        uid: '12ad1277-fbfa-41c8-a529-a013d9b63e2e',
        prefix: 'ls',
        status: false,
      },
      {
        uid: '0636f1c5-0017-4ff5-8086-0d35056ac43e',
        prefix: 'lt',
        status: false,
      },
      {
        uid: '407946f3-3168-4e0c-9404-34009fcbe300',
        prefix: 'lu',
        status: false,
      },
      {
        uid: '0fcd0c43-362e-4b4e-a903-d2f61ed8952d',
        prefix: 'lv',
        status: false,
      },
      {
        uid: '842a0f4c-441e-47e6-9419-fc121865c2ca',
        prefix: 'ly',
        status: false,
      },
      {
        uid: '1f4be7cb-b7ed-4783-b8a8-dd7a76fbf77b',
        prefix: 'ma',
        status: false,
      },
      {
        uid: '0b640c90-791a-4adb-b08f-d8d539e01298',
        prefix: 'mc',
        status: false,
      },
      {
        uid: '41fc1888-8ef5-40ee-bcfc-d50e18aa2b11',
        prefix: 'md',
        status: false,
      },
      {
        uid: 'dd3a9e8a-498c-4c82-9063-4285a008df1b',
        prefix: 'me',
        status: false,
      },
      {
        uid: '59daaadb-bf80-4afd-ac7b-5d53476be280',
        prefix: 'mf',
        status: false,
      },
      {
        uid: '2104e879-7700-4ec6-9539-2db342969c10',
        prefix: 'mg',
        status: false,
      },
      {
        uid: '8856c0af-af2d-43d7-a066-9cba463db6bc',
        prefix: 'mh',
        status: false,
      },
      {
        uid: 'd211b82e-6a0a-466c-9908-27f12a19d9e0',
        prefix: 'mk',
        status: false,
      },
      {
        uid: 'e18caa20-eb8c-49df-8bb3-45cc7f7a0b2a',
        prefix: 'ml',
        status: false,
      },
      {
        uid: '5c55192c-5f94-4739-a75c-391ca11c67aa',
        prefix: 'mm',
        status: false,
      },
      {
        uid: '5267e9f0-c4c1-4ec7-b062-557e2870d77d',
        prefix: 'mn',
        status: false,
      },
      {
        uid: '6283b174-3863-4c96-939d-f69440857dd4',
        prefix: 'mo',
        status: false,
      },
      {
        uid: 'ba46eae4-14be-4119-a7f9-b4556e141fda',
        prefix: 'mp',
        status: false,
      },
      {
        uid: '2df628cd-71ea-457d-aec7-fc0a673a2c48',
        prefix: 'mq',
        status: false,
      },
      {
        uid: '3a70fe82-87dc-43b6-ab31-688cb7c46829',
        prefix: 'mr',
        status: false,
      },
      {
        uid: '22b9eefc-8228-4716-adc6-2d798824b7d6',
        prefix: 'ms',
        status: false,
      },
      {
        uid: '034a4d2c-3428-44f3-a200-9bab855f5254',
        prefix: 'mt',
        status: false,
      },
      {
        uid: '79f4b110-4b19-4218-bb0b-8eba85a75622',
        prefix: 'mu',
        status: false,
      },
      {
        uid: '7f70582f-c39b-4ffa-9cef-94c202014df6',
        prefix: 'mv',
        status: false,
      },
      {
        uid: 'ebe81b00-ea7d-43c5-8a4c-69c5938847b7',
        prefix: 'mw',
        status: false,
      },
      {
        uid: 'c1ba1609-a0d1-4614-b678-85c2d661db66',
        prefix: 'mx',
        status: false,
      },
      {
        uid: '62fc5657-1920-47ce-9de9-8b32d2d6c38b',
        prefix: 'my',
        status: false,
      },
      {
        uid: '47a90f48-f3ba-4012-a914-3906d91fe0bb',
        prefix: 'mz',
        status: false,
      },
      {
        uid: 'cfa38c99-f9e1-4306-8859-12200325c3f5',
        prefix: 'na',
        status: false,
      },
      {
        uid: 'fba5d202-a9f1-4397-a7ec-7bbbc7527a9e',
        prefix: 'nc',
        status: false,
      },
      {
        uid: '370d2b3a-57c5-4985-989d-50a99b4598c5',
        prefix: 'ne',
        status: false,
      },
      {
        uid: '2a4d2147-8e8f-41df-aa57-4521a917ed53',
        prefix: 'nf',
        status: false,
      },
      {
        uid: 'b0e84587-e630-4ac7-ab18-3bd9303a622c',
        prefix: 'ng',
        status: false,
      },
      {
        uid: 'ebfee117-8cd0-473d-93e9-c60122d03c1c',
        prefix: 'ni',
        status: false,
      },
      {
        uid: '536b140e-8605-4833-a2ad-e7b256176796',
        prefix: 'nl',
        status: false,
      },
      {
        uid: '80eb4d96-40db-41e8-9409-c140ddbf7a60',
        prefix: 'no',
        status: false,
      },
      {
        uid: 'd2d9b999-e4df-4976-8028-7b1832303206',
        prefix: 'np',
        status: false,
      },
      {
        uid: '606a0f0b-f39f-46f1-bb4a-b5e5ae8b30e0',
        prefix: 'nr',
        status: false,
      },
      {
        uid: 'b821ee04-a5fb-4100-994e-bb8eb3052c16',
        prefix: 'nu',
        status: false,
      },
      {
        uid: '277beff6-e07e-4f56-8a9d-e2f8a49570ec',
        prefix: 'nz',
        status: false,
      },
      {
        uid: '27ff8d90-4387-403a-a5f1-4b2e9af22da2',
        prefix: 'om',
        status: false,
      },
      {
        uid: 'd4b4ecb1-139c-4409-bec4-1901539766e0',
        prefix: 'pa',
        status: false,
      },
      {
        uid: '641d87a8-3f0b-4984-acf3-6ef8d766ceb2',
        prefix: 'pe',
        status: false,
      },
      {
        uid: 'b616252a-4ba2-4c4f-84db-9367815ee358',
        prefix: 'pf',
        status: false,
      },
      {
        uid: '332b5f41-e08e-43f7-97d1-b8e0184356bb',
        prefix: 'pg',
        status: false,
      },
      {
        uid: '13647bb3-e22d-4d13-9d9c-f7b9c9f96e4c',
        prefix: 'ph',
        status: false,
      },
      {
        uid: 'ae18b27a-ef82-4809-9dee-c00cb6284384',
        prefix: 'pk',
        status: false,
      },
      {
        uid: '2d412c77-5dcd-4a01-8fcc-a7c51af1dfbe',
        prefix: 'pl',
        status: false,
      },
      {
        uid: '8119ce52-3e79-49ed-82b6-feb29393818c',
        prefix: 'pm',
        status: false,
      },
      {
        uid: '501cae5c-3a21-45d2-a96d-2727c8f46ef8',
        prefix: 'pn',
        status: false,
      },
      {
        uid: 'c81bcfa6-47e6-4595-9072-ac03f208a46a',
        prefix: 'pr',
        status: false,
      },
      {
        uid: '199e8bb2-03c2-4f49-8c09-94e75d954359',
        prefix: 'ps',
        status: false,
      },
      {
        uid: 'a8d0523b-ee8c-4cd2-acf3-56b615e98e80',
        prefix: 'pt',
        status: false,
      },
      {
        uid: '068d6151-4030-4c74-9199-f074e2eb7a79',
        prefix: 'pw',
        status: false,
      },
      {
        uid: '742315bf-0ab3-4b9a-a9db-0a890a02dad1',
        prefix: 'py',
        status: false,
      },
      {
        uid: '92748456-6ad0-4dd6-809a-c4da5a61678c',
        prefix: 'qa',
        status: false,
      },
      {
        uid: '9292bb9b-3c33-40fe-9c94-9901aac1f650',
        prefix: 're',
        status: false,
      },
      {
        uid: '4ec4e872-2ed3-4ea8-82bd-aa48b1a0698a',
        prefix: 'ro',
        status: false,
      },
      {
        uid: 'f5620cdd-4d7b-4e09-acff-8cb98c44d92a',
        prefix: 'rs',
        status: false,
      },
      {
        uid: '17e9708f-0446-49d1-bc98-1be20bf4ff11',
        prefix: 'ru',
        status: false,
      },
      {
        uid: '77097c58-9b7e-4451-867d-2d2d98e44211',
        prefix: 'rw',
        status: false,
      },
      {
        uid: '2fd780e7-80d2-4a23-9f00-9904fb46e0f0',
        prefix: 'sa',
        status: false,
      },
      {
        uid: '7f61765d-2d04-46f3-8610-fbe7f4f9a6a3',
        prefix: 'sb',
        status: false,
      },
      {
        uid: '502e03e5-a010-41d8-9965-63c1db2713a9',
        prefix: 'sc',
        status: false,
      },
      {
        uid: '2182d9ae-1116-4efe-bbd5-e02b625f8b63',
        prefix: 'sd',
        status: false,
      },
      {
        uid: 'f5153966-7101-4b72-a2b6-5dccaebeabc9',
        prefix: 'se',
        status: false,
      },
      {
        uid: '90405960-d19d-44bd-b72a-1f1b4b3aeba7',
        prefix: 'sg',
        status: false,
      },
      {
        uid: 'd93c11d6-50db-4ed6-90fa-0908af052ade',
        prefix: 'sh',
        status: false,
      },
      {
        uid: 'f65e11b4-0847-472a-954d-1813d62603e5',
        prefix: 'si',
        status: true,
      },
      {
        uid: '347f33e8-1f76-4a49-9b1b-a783f04e6adb',
        prefix: 'sj',
        status: false,
      },
      {
        uid: 'd6295430-3333-4ad9-a682-fa83920ce13b',
        prefix: 'sk',
        status: false,
      },
      {
        uid: 'bcd0437b-fc42-48ed-ad4a-d16b1db781f3',
        prefix: 'sl',
        status: false,
      },
      {
        uid: '0368bd70-31f5-44c1-9c84-b89a86d82079',
        prefix: 'sm',
        status: false,
      },
      {
        uid: '9bda73ef-7939-4447-aa9b-e6943413000d',
        prefix: 'sn',
        status: false,
      },
      {
        uid: '9e869a7e-f556-4822-9e82-39ffd95c694c',
        prefix: 'so',
        status: false,
      },
      {
        uid: '20f983b6-0836-4131-98c8-6e06b8322947',
        prefix: 'sr',
        status: false,
      },
      {
        uid: 'ee51a6d6-daaf-45f6-981a-9e12f0525465',
        prefix: 'ss',
        status: false,
      },
      {
        uid: 'f252a491-cafb-4eb2-bcaf-d5361d8a5873',
        prefix: 'st',
        status: false,
      },
      {
        uid: '86b6ef96-d328-4a9e-b747-5c1c5e537da2',
        prefix: 'sv',
        status: false,
      },
      {
        uid: '8fb7d80a-9256-462c-8066-012104070e5d',
        prefix: 'sx',
        status: false,
      },
      {
        uid: '22a15f5e-774b-485f-959e-0d9fba88de38',
        prefix: 'sy',
        status: false,
      },
      {
        uid: 'c9fa8e84-1571-44a2-a003-53252253523f',
        prefix: 'sz',
        status: false,
      },
      {
        uid: '3b00c3b8-37a9-4328-a272-008ab8c83a53',
        prefix: 'tc',
        status: false,
      },
      {
        uid: 'ca56b5f7-263b-4717-b10c-f1b7aa4d1fcb',
        prefix: 'td',
        status: false,
      },
      {
        uid: 'cfee6657-8650-46bd-a64e-2994f4d58831',
        prefix: 'tf',
        status: false,
      },
      {
        uid: '90635a60-624e-4c2c-a817-c822b9e8d79e',
        prefix: 'tg',
        status: false,
      },
      {
        uid: 'db82fb40-31b4-4796-9c9b-bf1df0657afe',
        prefix: 'th',
        status: false,
      },
      {
        uid: '2a406c26-e1e7-4317-9425-d7d12555a01a',
        prefix: 'tj',
        status: false,
      },
      {
        uid: '3594f8dd-da8f-4872-a82f-52120337e915',
        prefix: 'tk',
        status: false,
      },
      {
        uid: '584785bb-c065-4bf6-8174-f7f7b91dd3a1',
        prefix: 'tl',
        status: false,
      },
      {
        uid: '82ad532b-f9c4-4b36-b724-901d76508bdb',
        prefix: 'tm',
        status: false,
      },
      {
        uid: 'e37400d4-1c96-4921-a479-0046e22ffa13',
        prefix: 'tn',
        status: false,
      },
      {
        uid: 'a9c41275-31e0-42be-ba19-500940f82522',
        prefix: 'to',
        status: false,
      },
      {
        uid: 'c9626add-7a12-4e8a-b74d-0a1633a039a7',
        prefix: 'tr',
        status: false,
      },
      {
        uid: '92e60473-eb45-4aa7-a6a7-82b820919196',
        prefix: 'tt',
        status: false,
      },
      {
        uid: 'f1f8afe9-fd5b-48d6-b84f-944e1a7f8f8a',
        prefix: 'tv',
        status: false,
      },
      {
        uid: 'f316599d-6817-49bb-b270-8aa7a630d821',
        prefix: 'tw',
        status: false,
      },
      {
        uid: '5e614264-a707-4cbb-8d92-1597a3a8ecf9',
        prefix: 'tz',
        status: false,
      },
      {
        uid: '3692e04f-7381-41eb-a49b-72396a7cc44a',
        prefix: 'ua',
        status: false,
      },
      {
        uid: '94c63e63-7499-4498-9625-827846d56fd8',
        prefix: 'ug',
        status: false,
      },
      {
        uid: '9cc37cb0-1b48-48a2-8cfc-2394d9875573',
        prefix: 'um',
        status: false,
      },
      {
        uid: 'd75f06bc-0048-4da1-a86b-2cf4b1716bc6',
        prefix: 'us',
        status: false,
      },
      {
        uid: 'ed59528a-5019-4738-b044-f9df15cf1a0a',
        prefix: 'uy',
        status: false,
      },
      {
        uid: '970a93ab-84c5-4415-b18d-2b89f1a97c4a',
        prefix: 'uz',
        status: false,
      },
      {
        uid: '9f8c1b55-74bd-487d-abc5-c3a1a872cc05',
        prefix: 'va',
        status: false,
      },
      {
        uid: 'e4360168-fc75-4cdd-b5e2-4a3868de7c3c',
        prefix: 'vc',
        status: false,
      },
      {
        uid: '4d9676c3-e95d-47b8-af93-7c2d262b2b15',
        prefix: 've',
        status: false,
      },
      {
        uid: '903103e0-77ff-4f63-976b-db52072ed22b',
        prefix: 'vg',
        status: false,
      },
      {
        uid: '9af8803c-b6f1-4e5e-849a-0e74b9b22bb9',
        prefix: 'vi',
        status: false,
      },
      {
        uid: '32828650-10d8-4067-8bc5-f3fbce16c8f8',
        prefix: 'vn',
        status: false,
      },
      {
        uid: '9febca03-3250-449d-afbb-f4d4088811f6',
        prefix: 'vu',
        status: false,
      },
      {
        uid: '2d473233-cf4d-4198-bc9c-6e2c76fe0f28',
        prefix: 'wf',
        status: false,
      },
      {
        uid: '4fabca76-a08a-4136-a9d9-60809a2d9489',
        prefix: 'ws',
        status: false,
      },
      {
        uid: '69bc4431-b78f-41f3-9ff7-46e69a817b39',
        prefix: 'xk',
        status: false,
      },
      {
        uid: '83860ace-7175-4fbd-bdfa-8e02ed7bc1f3',
        prefix: 'ye',
        status: false,
      },
      {
        uid: '388c0325-2127-4889-8218-bec5247942c7',
        prefix: 'yt',
        status: false,
      },
      {
        uid: '34bfd85f-9827-400f-baff-9c4536e96eb8',
        prefix: 'za',
        status: false,
      },
      {
        uid: 'e1a19c4c-ebe2-4d9f-9502-502528019da0',
        prefix: 'zm',
        status: false,
      },
      {
        uid: '75240ce2-2c91-4085-88e3-516c9afb3f8b',
        prefix: 'zw',
        status: false,
      },
    ]);
  }
}
