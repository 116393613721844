import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';

import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs';

@Injectable()
export class RoleProvider implements NbRoleProvider {

  constructor(private authService: NbAuthService) {
  }

  getRole(): Observable<string> {
    if (environment.use_mock)
      return of('SU');
    else
      return this.authService.onTokenChange()
        .pipe(
          map((token: NbAuthJWTToken) => {
            return token.isValid() ? token.getPayload()['roles'] : 'GUEST';
          }),
        );
  }
}
