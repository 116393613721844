import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../../environments/environment';

@Injectable()
export class GenericApiService {
  protected url = '';

  constructor() {
    this.url = environment.api_url;
  }
}
