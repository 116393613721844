import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PersonsData } from '../data/persons';
import { Observable, of } from 'rxjs';
import { ListFetchOptions, ListFetchTypeOptions, RestFrameworkListResponse } from '../data/rest-framework';
import { GroupData } from '../data/group';
import { GroupsMockService } from './groups.mock.service';
import { PersonGroupsMockService } from './person-group.mock.service';
import { DepFlags } from '@angular/compiler/src/core';
import { PersonGroupData } from '../data/person-group';

@Injectable()
export class PersonsMockService extends PersonsData {

  private isString(obj): boolean {
    return (Object.prototype.toString.call(obj) === '[object String]');
  }
  // pobieranie wszystkich person z danej grupy
  public readListType(options: ListFetchTypeOptions): Observable<RestFrameworkListResponse<PersonsData> | any> {
    const dt: Array<any> = this.data as Array<any>;
    let d: Array<any> = [];
    if (this.isString(options.type) === false || options.type === '') {
      d = dt;
    } else {
      const uids = this.pgSrv.getGroupPersons(options.type);
      for (let i = 0; i < uids.length; i++) {
        const person = dt.find(e => e.uid === uids[i]);
        if (person !== undefined) d.push(person);
      }
    }
    if (options.search) {
      d = d.filter((v) => {
        let search = '';
        Object.keys(v).forEach((key) => {
          search += ' ' + v[key];
        });
        return search.indexOf(options.search) !== -1;
      });
    }

    const page: number = options.page ? options.page : 1;
    const page_size: number = options.page_size ? options.page_size : 20;
    const out = d.slice(
      (page - 1) * page_size,
      (page - 1) * page_size + page_size,
    );
    return of({
      next: null,
      previous: null,
      count: d.length,
      max_page_size: 100,
      results: out,
    });
  }


  public educateMany(_: string[]): Observable<any> {
    return of({
      status: 'success',
      info: 'success',
    });
  }

  public educate(_: string): Observable<any> {
    return of({
      status: 'success',
      info: 'success',
    });
  }

  public upload(_: File): Observable<any> {
    return of({
      status: 'success',
    });
  }

  constructor(
    public pgSrv: PersonGroupData,
  ) {

    super('PERSON', [
      {
        uid: '05c4f446-a31c-42be-ba62-4e9a7f432402',
        firstname: 'barMock',
        lastname: 'Fabjanczuk',
        isMale: true,
        position: 'CEO',
        email: 'bartosz.fabjanczuk@mysza1.software19',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: '14ca3d03-f30a-4b78-ba3b-d27e883be5a1',
        firstname: 'far',
        lastname: 'babjanczuk',
        isMale: false,
        position: 'CEOF',
        email: 'bartosz.babjanczuk@mysza.software14',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: '1d4e983b-829f-4d73-b509-0a552795ad86',
        firstname: 'bar',
        lastname: 'Fabjanczuk',
        isMale: true,
        position: 'CEO',
        email: 'bartosz.fabjanczuk@mysza1.software',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: '30865fe0-9dad-4863-80b3-1e60a24eeaf5',
        firstname: 'far',
        lastname: 'babjanczuk',
        isMale: false,
        position: 'CEOF',
        email: 'bartosz.babjanczuk@mysza.software12',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: '36d44437-52ee-4331-81a3-4ef3d6701376',
        firstname: 'far',
        lastname: 'babjanczuk',
        isMale: false,
        position: 'CEOF',
        email: 'bartosz.babjanczuk@mysza.software26',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: '4072c015-3d9f-4a2e-8d7f-73b4119f9441',
        firstname: 'far',
        lastname: 'babjanczuk',
        isMale: false,
        position: 'CEOF',
        email: 'bartosz.babjanczuk@mysza.software8',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: '40b6670c-97b0-49ab-83a7-8b2e705ac025',
        firstname: 'bar',
        lastname: 'Fabjanczuk',
        isMale: true,
        position: 'CEO',
        email: 'bartosz.fabjanczuk@mysza1.software5',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: '5819d037-6ba5-442a-9ff0-be09853fd1cb',
        firstname: 'far',
        lastname: 'babjanczuk',
        isMale: false,
        position: 'CEOF',
        email: 'bartosz.babjanczuk@mysza.software18',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: '6e29e721-243e-49e7-9e5d-611346d529d4',
        firstname: 'far',
        lastname: 'babjanczuk',
        isMale: false,
        position: 'CEOF',
        email: 'bartosz.babjanczuk@mysza.software32',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: '6fdc8929-0754-4ffe-93a0-fe63f1460232',
        firstname: 'far',
        lastname: 'babjanczuk',
        isMale: false,
        position: 'CEOF',
        email: 'bartosz.babjanczuk@mysza.software10',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: '8f1c3016-06df-47cd-bfbd-0264a127b261',
        firstname: 'far',
        lastname: 'babjanczuk',
        isMale: false,
        position: 'CEOF',
        email: 'bartosz.babjanczuk@mysza.software20',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: '8f8cce7f-f715-42be-8f90-e483054a9110',
        firstname: 'far',
        lastname: 'babjanczuk',
        isMale: false,
        position: 'CEOF',
        email: 'bartosz.babjanczuk@mysza.software6',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: '90ab645d-bc20-41df-8ba5-e1cd8d26b327',
        firstname: 'bar',
        lastname: 'Fabjanczuk',
        isMale: true,
        position: 'CEO',
        email: 'bartosz.fabjanczuk@mysza1.software9',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: '90cfc901-8ea8-4387-a4af-8922b0ac4e64',
        firstname: 'bar',
        lastname: 'Fabjanczuk',
        isMale: true,
        position: 'CEO',
        email: 'bartosz.fabjanczuk@mysza1.software13',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: '917d3cc6-5449-4707-81dd-882dcc3cfa50',
        firstname: 'far',
        lastname: 'babjanczuk',
        isMale: false,
        position: 'CEOF',
        email: 'bartosz.babjanczuk@mysza.software30',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: '9905522e-f72d-4ecd-992e-dddb3873b473',
        firstname: 'bar',
        lastname: 'Fabjanczuk',
        isMale: true,
        position: 'CEO',
        email: 'bartosz.fabjanczuk@mysza1.software17',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: 'a240d61e-f703-48c8-945c-a4e0900f377f',
        firstname: 'bar',
        lastname: 'Fabjanczuk',
        isMale: true,
        position: 'CEO',
        email: 'bartosz.fabjanczuk@mysza1.software25',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: 'b335f7ff-40b7-4e27-a5f3-72105591c228',
        firstname: 'bar',
        lastname: 'Fabjanczuk',
        isMale: true,
        position: 'CEO',
        email: 'bartosz.fabjanczuk@mysza1.software15',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: 'bf0fca64-43e2-40c3-a225-54cf11cab904',
        firstname: 'bar',
        lastname: 'Fabjanczuk',
        isMale: true,
        position: 'CEO',
        email: 'bartosz.fabjanczuk@mysza1.software11',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
      {
        uid: 'c3bed9ce-d40d-4c60-a6b7-605f352b32df',
        firstname: 'bar',
        lastname: 'Fabjanczuk',
        isMale: true,
        position: 'CEO',
        email: 'bartosz.fabjanczuk@mysza1.software7',
        phone: 111333222,
        comment: 'ccccc',
        extra: 0,
        username: 'user1',
        computername: 'computer1',
      },
    ]);
  }
}
