import { Injectable } from '@angular/core';
import {
  SessionChangePasswordRequest,
  SessionChangePasswordResponse,
  SessionData,
} from '../data/session';
import { Observable, of } from 'rxjs';

@Injectable()
export class SessionMockService extends SessionData {

  constructor() {
    super('Session', []);
  }

  public changePassword(_: SessionChangePasswordRequest): Observable<SessionChangePasswordResponse | any> {
    return of({
      status: 'success',
    });
  }
}
